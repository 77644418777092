import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Collapsible } from 'components/Forms/FieldArray/Collapsible';
import PickupLocation from './PickupAddress';
import DestinationLocation from './DestAddress';
import Horses from './Horses';
import { transportGroupSchema, horseSchema } from 'helpers/contentHelper';
import { validateGroupSchema, validateAddresses } from 'helpers/validateStepperSchema';
import { DateTimePicker } from 'components/Forms/Fields/DateTimePicker';
import { useTranslation } from 'react-i18next';
import { sweetToast } from 'helpers/common';

const DeleteGroup=({arrayHelpers1, index})=>{
  const { t } = useTranslation();
  return (
    <button type="button" className="float-right btn btn-outline-danger add-group-button m-r-p-1" onClick={() => arrayHelpers1.remove(index)}>{t('Delete Horse')}</button>
  )
}

export const GroupList = ({ props }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h4>{t('Create Request Group')}</h4>
      <FieldArray
        name="transportGroups"
        render={arrayHelpers => (
          <div className='transport-root'>
            <div className='row ml-0 mr-0'>
              <div className='col-md-12 text-right'>
                <button type="button" className="btn btn-outline-primary add-group-button m-r-p-11" onClick={() => arrayHelpers.push(transportGroupSchema)}>{t('Add Group')}</button>
              </div>
            </div>
            {props.values.transportGroups && props.values.transportGroups.length > 0 ? (
              props.values.transportGroups.map((group, index) => (
                <div key={index} className='m-t-p-3'>
                  <div className='group-border p-p-3 m-t-p-3'>
                    <div className='m-l-p-1'>
                      <Fragment>
                        <div className='transport-collapse-heading'>
                          <div className='row full-width-group-sub-heading'>
                            <div className='col-md-6 text-left'>
                              <label>{t('Tranport Group')} #{index + 1}</label>
                            </div>
                            {props.values.transportGroups.length !== 1 && (
                              <div className='col-md-6 text-right'>
                                <button type="button" onClick={() => arrayHelpers.remove(index)} className="btn btn-outline-danger">{t('Delete Group')}</button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <div className='row full-width dates-div'>
                            <div className='m-l-p-1 width-50-p'>
                              <div className='row m-l--10'>
                                <div className='col-md-6 text-left'>
                                  <label>{t('As of Date')}<span className='required-field'>*</span></label>
                                  <DateTimePicker
                                    setDate={props.setDate}
                                    selectField={props.values.transportGroups[index].periodStart}
                                    name={`transportGroups.${index}.periodStart`}
                                    type='periodStart'
                                  />
                                </div>
                                <div className='col-md-6 text-left'>
                                  <label>{t('Up To Date')}<span className='required-field'>*</span></label>
                                  <DateTimePicker
                                    setDate={props.setDate}
                                    selectField={props.values.transportGroups[index].periodEnd}
                                    name={`transportGroups.${index}.periodEnd`}
                                    type='periodEnd'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>

                      <div className='m-l-p--3'>
                        <Collapsible componentList={t('Pick up Address')} index={`pickup-${index + 1}`} selectedIndex={props.selectedIndex} changeIndex={props.changeIndex}>
                          <PickupLocation
                            groupName="transportGroups"
                            index={index}
                            {...props}
                            group={group}
                          />
                        </Collapsible>
                      </div>
                      <div className='m-l-p--3'>
                        <Collapsible componentList={t('Destination Address')} index={`destination-${index + 1}`} selectedIndex={props.selectedIndex} changeIndex={props.changeIndex}>
                          <DestinationLocation
                            groupName="transportGroups"
                            index={index}
                            {...props}
                          />
                        </Collapsible>
                      </div>
                      <FieldArray
                        name={`transportGroups.${index}.horses`}
                        render={arrayHelpers1 => (
                          <div className='m-l-p--3'>
                            {props.values.transportGroups[index].horses.map((horse, horseIndex) => (
                              <Fragment key={horseIndex}>
                                <div className='row full-width m-l-1'>
                                  <div className='full-width' >
                                    <Collapsible componentList={t('Horse Info')} index={`Trans - ${horseIndex} - ${index}`} selectedIndex={props.selectedIndex} changeIndex={props.changeIndex} DeleteGroup={DeleteGroup} arrayHelpers={arrayHelpers1} showDelete={ props.values.transportGroups[index].horses.length>1} >
                                      <Horses
                                        groupName={`transportGroups.${index}.horses`}
                                        index={horseIndex}
                                        {...props}
                                      />
                                    </Collapsible>
                                  </div>
                                </div>
                              </Fragment>
                            ))}
                            <div className='row full-width m-t-p-2'>
                              <div className='col-md-12'>
                                <button type="button" className="float-right btn btn-outline-success add-group-button m-r-p-1" onClick={() => arrayHelpers1.push(horseSchema)}>{t('Add Horse')}</button>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <button type="button" onClick={() => arrayHelpers.push(transportGroupSchema)}>
                {t('Add a Group')}
              </button>
            )}
          </div>
        )}
      />
    </div>
  )
  ;
};

GroupList.propTypes = {
  values: PropTypes.object,
  props: PropTypes.any,
  selectedIndex: PropTypes.number,
  changeIndex: PropTypes.func,
  onLocationSelect: PropTypes.func,
  setDate: PropTypes.func
};

export class TransportGroup extends Component {
  constructor (props) {
    super(props);

    this.state = {
      selectedIndex: '',
      location: {
        street: false,
        zipCode: false,
        city: false,
        country: false
      }
    };
  }

  static propTypes = {
    setFieldValue: PropTypes.func,
    values: PropTypes.object
  }

  setDate = (date, type) => {
    const { setFieldValue } = this.props;
    setFieldValue(`${type}`, date);
  };

  isValidated () {
    const { values, t } = this.props;
    const response = validateGroupSchema(values);
    if (typeof response === 'boolean' && response===true) {
      return validateAddresses(values,t);
    } else if(!response.status) {
      sweetToast('error', `${t(`${response.key}`)} ${response.index+1}: ${t(`${response.msg}`)}`);
      return false;
    }
  }

  changeIndex = (index) => {
    const { selectedIndex } = this.state;
    if (selectedIndex === index) {
      this.setState({
        selectedIndex: ''
      });
    } else {
      this.setState({
        selectedIndex: index
      });
    }
  };

  resetStateForColors = () => {
    this.setState(state => ({
      ...state, location: { street: false, zipCode: false, city: false, country: false }
    }));
  }

  onLocationSelect = (addressObject) => {
    const { setFieldValue } = this.props;
    const { selectedIndex } = this.state;
    addressObject.address_components.map((obj) => {
      if (obj.types.includes('locality') && obj.types.includes('political')) {
        this.setState(state => ({
          ...state, location: { ...state.location, city: true }
        }));
        setFieldValue(`transportGroups.${(selectedIndex.split('-'))[1] - 1}.${(selectedIndex.split('-'))[0]}.city`, obj.long_name);
      } else if (obj.types.includes('country') && obj.types.includes('political')) {
        this.setState(state => ({
          ...state, location: { ...state.location, country: true }
        }));
        setFieldValue(`transportGroups.${(selectedIndex.split('-'))[1] - 1}.${(selectedIndex.split('-'))[0]}.state`, obj.long_name);
        setFieldValue(`transportGroups.${(selectedIndex.split('-'))[1] - 1}.${(selectedIndex.split('-'))[0]}.country`, obj.short_name);
      } else if (obj.types.includes('postal_code')) {
        this.setState(state => ({
          ...state, location: { ...state.location, zipCode: true }
        }));
        setFieldValue(`transportGroups.${(selectedIndex.split('-'))[1] - 1}.${(selectedIndex.split('-'))[0]}.zipCode`, obj.long_name);
      } else if (obj.types.includes('route')) {
        this.setState(state => ({
          ...state, location: { ...state.location, street: true }
        }));
        setFieldValue(`transportGroups.${(selectedIndex.split('-'))[1] - 1}.${(selectedIndex.split('-'))[0]}.street`, obj.long_name);
      }
      return 0
    });
    setTimeout(() => { this.resetStateForColors(); }, 3000);
  };

  render () {
    const { selectedIndex, location } = this.state;
    return (
      <div>
        <GroupList props={{ ...this.props, setDate: this.setDate, selectedIndex: selectedIndex, changeIndex: this.changeIndex, onLocationSelect: this.onLocationSelect, location: location }} />
      </div>
    );
  }
};

export default TransportGroup;
