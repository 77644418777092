import React from 'react';

function imprint(props) {
    return (
        <div className='text-left padding-all-2'>
        <h1>Impressum</h1>
<p><span>Internationale Pferdetransporte</span><br /><span>Ingo M&uuml;ller</span><br /><span>Dedendorf 14</span><br /><span>27333 B&uuml;cken</span><br /><span>Tel.: +49 (42 51) 32 61</span><br /><span>Fax: +49 (42 51) 33 59</span><br /><br /><span>E-Mail: info@mueller-pferdetransporte.de</span><br /><span>www.mueller-pferdetransporte.de</span><br /><br /><span>Inhaber der Firmen: Ingo M&uuml;ller</span><br /><span>USt-IdNr.: DE153072835</span></p>


    </div>
    );
}

export default imprint;