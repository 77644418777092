import { GET_REQUESTS_LIST } from './request.types';
import { pending, fulfilled } from 'helpers/api';
import humps from 'humps';
import { deformatRequests } from 'helpers/dataDeformater';
import { ifKeyExist } from 'helpers/common';

const initial = {
  requests: {}
};

export default function (state = initial, action) {
  switch (action.type) {
    case pending(GET_REQUESTS_LIST):
      return { ...state };
    case fulfilled(GET_REQUESTS_LIST):
      return { ...state, requests: ifKeyExist(action.payload, 'data.response.data') ? deformatRequests(humps.camelizeKeys(action.payload.data.response.data)) : [] };
    case 'CLEAR_REQUESTS':
      return { ...state, requests: {} };
    default:
      return state;
  }
}
