import { urls } from './urls';
import * as actionType from './types';
import { newRequest } from 'helpers/api';

export function verifyRecaptchaToken(siteKey, token, server) {

  const requestObject = {
    url : urls.verifyToken(siteKey, token),
    method: 'POST',
    data: {
      secret: siteKey,
      response: token
    }
  };
  return {
    type: actionType.VERIFY_TOKEN,
    payload: newRequest(requestObject, server),
  };
}