export const initialDate = {
  invoiceAddress: {
    firstname: '',
    lastname: '',
    companyName: '',
    secondaryCompanyName: '',
    street: '',
    zipCode: '',
    city: '',
    state: '',
    salutation: '',
    vatId: '',
    country:'DE',
    phoneNumber: '',
    email: ''
  },
  transportGroups: [{
    periodStart: '',
    periodEnd: '',
    pickup: {
      farmName: '',
      name: '',
      street: '',
      zipCode: '',
      city: '',
      state: '',
      country: 'DE'
    },
    destination: {
      farmName: '',
      name: '',
      street: '',
      zipCode: '',
      city: '',
      state: '',
      country: 'DE'
    },
    horses: [{
      gender: '',
      race: '',
      name: '',
      lifeNumber: '',
      age: '',
      height: '',
      type: '',
      comment: ''
    }]
  }]
};
