import React, { Component, Fragment } from 'react';
import AppRoutes from 'routes/app.routes';
import './App.css';
import './i18n';
import AppInfo from 'components/App/AppInfo';
import Loader from 'components/Client/ContentLoader/ContentLoader';

class App extends Component {

  constructor(props){
    super(props)
    this.state = {
      showAppInfo: localStorage.getItem("showAppInfo")
    }
  }

  componentDidMount(){
    localStorage.setItem("showAppInfo", false)
  }

  render () {
    return (
      <Fragment>
        <Loader type="BlockUi" loadingRef="modules.globalLoading">
          <div className="App">
            <AppRoutes {...this.props}></AppRoutes>
            {this.state.showAppInfo !== 'false' && <AppInfo />}
          </div>
        </Loader>
      </Fragment>
    );
  }
}

export default App;
