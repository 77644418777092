import * as Yup from 'yup';

export const resetPasswordConfirmSchema = Yup.object().shape({
  email: Yup.string()
  .email('Invalid email address')
  .required('Required'),
  password: Yup.string()
  .min(8, 'Atleast 8 characters')
  .max(20, 'Too Long!')
  .required('Required'),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Atleast 8 characters')
    .max(20, 'Too Long!')
    .required('Required')
});

export const registerSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required'),
  lastName: Yup.string()
    .required('Required'),
  salutation: Yup.string()
    .required('Required'),
  street: Yup.string()
    .required('Required'),
  zipCode: Yup.string()
    .required('Required'),
  city: Yup.string()
    .required('Required'),
  country: Yup.string()
    .required('Required'),
  phoneNumber: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Atleast 8 characters')
    .max(20, 'Too Long!')
    .required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),

});

export const transportRequestSchema = Yup.object().shape({
  invoicefirstname: Yup.string()
    .required('Required'),
  lastname: Yup.string()
    .required('Required'),
  street: Yup.string()
    .required('Required'),
  zipCode: Yup.string()
    .required('Required'),
  city: Yup.string()
    .required('Required'),
  salutation: Yup.string()
    .required('Required'),
  country: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  tranportGroup: Yup.array()
    .of(
      Yup.object().shape({
        periodStart: Yup.string()
          .required('Required'),
        periodEnd: Yup.string()
          .required('Required'),
        pickup: Yup.object().shape({
          name: Yup.string()
            .required('Required'),
          street: Yup.string()
            .required('Required'),
          zipCode: Yup.string()
            .required('Required'),
          city: Yup.string()
            .required('Required'),
          country: Yup.string()
            .required('Required')
        }),
        destination: Yup.object().shape({
          name: Yup.string()
            .required('Required'),
          street: Yup.string()
            .required('Required'),
          zipCode: Yup.string()
            .required('Required'),
          city: Yup.string()
            .required('Required'),
          country: Yup.string()
            .required('Required')
        }),
        horses: Yup.array()
          .of(
            Yup.object().shape({

              gender: Yup.string()
                .required('Required'),
              race: Yup.string()
                .required('Required'),
              name: Yup.string()
                .required('Required'),
              lifeNumber: Yup.string()
                .required('Required'),
              age: Yup.string()
                .required('Required'),
              height: Yup.string()
                .required('Required'),
              type: Yup.string()
                .required('Required'),
              comment: Yup.string()
                .required('Required')

            })
          )
      })
    )
});
