import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import RequestComponent from 'components/Client/TransportRequest/Index';
import PreviousRequests from 'components/Client/Requests/RequestList';
import { logoutUser } from 'store/modules/user/user.action';
import { clearRequests } from 'store/modules/request/request.action';
import ProtectedRoute from 'routes/protected.route';
import AppNavBar from 'components/App/AppNavBar';
// import { logoutUser } from 'store/action';

export function MainPage (props) {

  return (
    <>
      <Router>
        <div className="App">
          <AppNavBar  {...props} ></AppNavBar>
          
          <Switch>
            <Route path="/" component={RequestComponent}/>
            <ProtectedRoute path='/requests' component={PreviousRequests} />
          </Switch>
        </div>
      </Router>
    </>
  );
}

MainPage.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
  dispatch: PropTypes.func,
  clearRequests: PropTypes.func
};

export default connect(null, { logoutUser, clearRequests })(MainPage);
