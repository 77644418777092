import React, {useState, Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { resetPasswordSchema } from 'helpers/validation';
import { ifKeyExist } from 'helpers/common';

import { globalLoading } from 'store/modules/index';
import { resetPassword } from 'store/modules/user/user.action';
import { useTranslation, Trans } from 'react-i18next';
import {msgAlert} from 'helpers/common';

import { ReCaptcha } from 'react-recaptcha-v3';
import { verifyRecaptchaToken } from 'store/modules/raCaptcha/actions';
import {publicKey, privateKey} from 'helpers/private/PRIVATE_KEYS';
import InputField from 'components/UI/InputField';

let formikErrors = {};
export const ResetPasswordForm = (props) => {
  const [isValidate, setValidate]= useState(false);
  const [dataValues, setValues]= useState({});
  const { t } = useTranslation();

  const verifyCallback= async (recaptchaToken) => {
    const { verifyRecaptchaToken, globalLoading, resetPassword } = props;
    globalLoading(true);
    const res = await verifyRecaptchaToken(privateKey, recaptchaToken);
    if (ifKeyExist(res, 'value.data.data.success')) {
      if (res.value.data.data.success === true) {
        try{
          const resetRes = await resetPassword(dataValues.email);
          // setSubmitting(false);
          if (resetRes.value && resetRes.value.data.status) {
            msgAlert('success', t('We\'ve emailed you instructions for setting your password, if an account exists with the email you entered.You should receive them shortly.'),t);
          }
        }catch(err){
          formikErrors(err.data);
        }
        
      } else {
        msgAlert( 'failure', t('Malicious activity found'),t );
      }
    }
    globalLoading(false);
  }


  return (
    <Fragment>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={resetPasswordSchema}
        onSubmit={ async (values, { setErrors }) => {
          formikErrors =  setErrors;
          setValues(values);
          setValidate(true);
          setValidate(false);
          props.globalLoading(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <h3><Trans>Reset Password</Trans></h3>
            <div className="form-group">
              <InputField type="email" name="email" placeholder={t('Email Address')} label="Email Address"  required/>
            </div>
            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
              <Trans>Reset</Trans>
            </button>


            <p className="forgot-password text-left">
              <Trans>Dont have any account</Trans>? <Link to='/register'><Trans>Register here</Trans></Link>
            </p>
            <p className="forgot-password text-left">
              <Trans>Already registered</Trans>? <Link to={'/login'}><Trans>sign in</Trans></Link>
            </p>
            

            {isValidate && (
              <ReCaptcha
                sitekey={publicKey}
                action="RequestPage"
                verifyCallback={ verifyCallback }
              />
            )}
          </Form>
        )}
      </Formik>
      
  </Fragment>
  );
};

ResetPasswordForm.propTypes = {
  login: PropTypes.func,
  history: PropTypes.object,
  globalLoading: PropTypes.func
};

export default connect(null, { resetPassword, globalLoading, verifyRecaptchaToken })(ResetPasswordForm);
