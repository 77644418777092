import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

import { isLogin } from 'helpers/auth';
import SearchField from 'components/Forms/Fields/SearchLocationField';
import CountriesField from 'components/Forms/Fields/CountriesPicker';
import { validateInvoiceSchema } from 'helpers/validateStepperSchema';
import { sweetToast } from 'helpers/common';
import { Trans } from 'react-i18next';
import _ from 'underscore';
export class InvoiceAddress extends Component {
  constructor (props) {
    super(props);

    this.state = {
      location: {
        street: false,
        zipCode: false,
        city: false,
        country: false
      }
    };
  }

  componentDidMount () {
    if (isLogin()) {
      const { setFieldValue, user, values } = this.props;
      // const user = getUser();
      if (!_.isEmpty(user)) {
        setFieldValue('invoiceAddress.firstname', values.invoiceAddress.firstname ? values.invoiceAddress.firstname : user.firstName);
        setFieldValue('invoiceAddress.lastname', values.invoiceAddress.lastname ? values.invoiceAddress.lastname : user.lastName);
        setFieldValue('invoiceAddress.companyName', values.invoiceAddress.companyName ? values.invoiceAddress.companyName : user.companyName);
        setFieldValue('invoiceAddress.secondaryCompanyName', values.invoiceAddress.secondaryCompanyName ? values.invoiceAddress.secondaryCompanyName : user.secondaryCompanyName);
        setFieldValue('invoiceAddress.street', values.invoiceAddress.street ? values.invoiceAddress.street : user.street);
        setFieldValue('invoiceAddress.zipCode', values.invoiceAddress.zipCode ? values.invoiceAddress.zipCode : user.zipCode);
        setFieldValue('invoiceAddress.city', values.invoiceAddress.city ? values.invoiceAddress.city : user.city);
        setFieldValue('invoiceAddress.state', values.invoiceAddress.state ? values.invoiceAddress.state : user.country);
        setFieldValue('invoiceAddress.country', values.invoiceAddress.country ? values.invoiceAddress.country : user.country);
        setFieldValue('invoiceAddress.email', values.invoiceAddress.email ? values.invoiceAddress.email : user.email);
        setFieldValue('invoiceAddress.vatId', values.invoiceAddress.vatId ? values.invoiceAddress.vatId : user.vatId);
        setFieldValue('invoiceAddress.salutation', values.invoiceAddress.salutation ? values.invoiceAddress.salutation : user.salutation);
        setFieldValue('invoiceAddress.phoneNumber', values.invoiceAddress.phoneNumber ? values.invoiceAddress.phoneNumber : user.phoneNumber);
      }
    }
  }

  validateField = (value) => {
    let error;
    if (value === '' || !value) {
      error = 'Required';
    }
    return error;
  }
  
  validateEmail = (value) => {
    let error;
    if(!value || value===''){
      error='Required';
    }
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  }

  isValidated () {
    const { values, t } = this.props;
    const response = validateInvoiceSchema(values);
    if( typeof response==='boolean' && response===true){
      return true;
    } else {
      const error = response;
      sweetToast('error', t(`${error.msg}`));
      return false;
    }
  };

  resetState = () => {
    this.setState(state => ({
      ...state, location: { street: false, zipCode: false, city: false, state: false }
    }));
  }

  onLocationSelect = (addressObject) => {
    const { setFieldValue, t } = this.props;
    addressObject.address_components.map((obj) => {
      if (obj.types.includes('locality') && obj.types.includes('political')) {
        this.setState(state => ({
          ...state, location: { ...state.location, city: true }
        }));
        setFieldValue('invoiceAddress.city', obj.long_name);
      } else if (obj.types.includes('country') && obj.types.includes('political')) {
        this.setState(state => ({
          ...state, location: { ...state.location, country: true }
        }));
        setFieldValue('invoiceAddress.state', obj.long_name);
        setFieldValue('invoiceAddress.country', obj.short_name);
      } else if (obj.types.includes('postal_code')) {
        this.setState(state => ({
          ...state, location: { ...state.location, zipCode: true }
        }));
        setFieldValue('invoiceAddress.zipCode', obj.long_name);
      } else if (obj.types.includes('route')) {
        this.setState(state => ({
          ...state, location: { ...state.location, street: true }
        }));
        setFieldValue('invoiceAddress.street', obj.long_name);
        if (!(/\d/.test(obj.long_name))) {
          sweetToast('error', t('Street should contain a number'));
        }
      }
        return 0;

    });
    setTimeout(() => { this.resetState(); }, 3000);
  };

  render () {
    const { t } = this.props;
    const { location } = this.state;
    return (
      <div>
        <h3><Trans>Invoice Address</Trans></h3>
        <div className='row invoice-header'>
          <div className='col-md-12'>
            <SearchField id='autocomplete' onLocationSelect={this.onLocationSelect} t={t} />
          </div>
        </div>
        <div className="auth-wrapper">
          <div className="steps-inner">
            <div className='row'>
              <div className="col-sm-4 form-group">
                <label><Trans>First Name</Trans><span className='required-field'>*</span></label>
                <Field type="text" className="form-control" name="invoiceAddress.firstname" validate={this.validateField} placeholder={t('First Name')} />
                <ErrorMessage  name="invoiceAddress.firstname">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
              </div>

              <div className="col-sm-4 form-group">
                <label><Trans>Last Name</Trans><span className='required-field'>*</span></label>
                <Field type="text" className="form-control" name="invoiceAddress.lastname" validate={this.validateField} placeholder={t('Last Name')} />
                <ErrorMessage  name="invoiceAddress.lastname">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
              </div>

              <div className="col-sm-4 form-group">
                <label><Trans>Salutation</Trans><span className='required-field'>*</span></label>
                <Field as='select' type='text' className='form-control' name='invoiceAddress.salutation' validate={this.validateField} placeholder={t('Salutation')}>
                  <option value="">--- {t('Select')} ---</option>
                  <option value='Mr.'>{t('Mr.')}</option>
                  <option value='Ms.'>{t('Ms.')}</option>
                </Field>
              </div>

              <div className="col-sm-4 form-group">
                <label><Trans>Company Name</Trans></label>
                <Field type="text" className="form-control" name="invoiceAddress.companyName" placeholder={t('Company Name')} />
                <ErrorMessage  name="invoiceAddress.companyName">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
              </div>

              <div className="col-sm-4 form-group">
                <label><Trans>Secondary Company Name</Trans></label>
                <Field type="text" className="form-control" name="invoiceAddress.secondaryCompanyName" placeholder={t('Secondary Company Name')} />
               
                <ErrorMessage  name="invoiceAddress.secondaryCompanyName">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
              </div>

              <div className="col-sm-4 form-group">
                <label><Trans>Street</Trans><span className='required-field'>*</span></label>
                <Field type="text" className={`form-control ${location.street ? 'fields-shadow' : ''}`} name="invoiceAddress.street" validate={this.validateField} placeholder={t('Street')} />
                <ErrorMessage  name="invoiceAddress.street">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
              </div>

              <div className="col-sm-4 form-group">
                <label><Trans>Zip-Code</Trans><span className='required-field'>*</span></label>
                <Field type="text" className={`form-control ${location.zipCode ? 'fields-shadow' : ''}`} name="invoiceAddress.zipCode" validate={this.validateField} placeholder={t('Zip-Code')} />
                <ErrorMessage  name="invoiceAddress.zipCode">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
              </div>

              <div className="col-sm-4 form-group">
                <label><Trans>City</Trans><span className='required-field'>*</span></label>
                <Field type="text" className={`form-control ${location.city ? 'fields-shadow' : ''}`} name="invoiceAddress.city" validate={this.validateField} placeholder={t('City')} />
                <ErrorMessage  name="invoiceAddress.city">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
              </div>

              <div className="col-sm-4 form-group">
                <label><Trans>Country</Trans><span className='required-field'>*</span></label>
                <CountriesField location={location} name='invoiceAddress.country' validate={this.validateField}/>
                <ErrorMessage  name="invoiceAddress.country">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
              </div>

              <div className="col-sm-4 form-group">
                <label><Trans>VAT-ID</Trans></label>
                <Field type="text" className="form-control" name="invoiceAddress.vatId" placeholder={'VAT-ID'} />
                <ErrorMessage  name="invoiceAddress.vatId">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
              </div>

              <div className="col-sm-4 form-group">
                <label><Trans>Email Address</Trans><span className='required-field'>*</span></label>
                <Field type="email" className="form-control" name="invoiceAddress.email" validate={this.validateEmail} placeholder={t('email')} />
                <ErrorMessage  name="invoiceAddress.email">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
              </div>

              <div className="col-sm-4 form-group">
                <label><Trans>Telephone</Trans></label>
                <Field type="number" className="form-control" name="invoiceAddress.phoneNumber" placeholder={t('Telephone')} />
                <ErrorMessage  name="invoiceAddress.phoneNumber">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

InvoiceAddress.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  t: PropTypes.any,
  user: PropTypes.object
};
export default InvoiceAddress;
