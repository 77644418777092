import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'components/Forms/Table/Table';
import { Trans, useTranslation } from 'react-i18next';

RequestDetails.propTypes = {
  request: PropTypes.object,
  history: PropTypes.object
};

function RequestDetails(props) {
  const { t } = useTranslation();
  const { request } = props;
  const headings = {
    id: 'ID',
    name: `${t('Name')}`,
    gender: `${t('Gender')}`,
    age: `${t('Age')}`,
    height: `${t('Height')}`,
    type: `${t('Type')}`,
    lifeNumber: `${t('UELN')}`
  };

  const getRows = (index) => {
    return request.transportGroups[index].horses.map((horse, horseIndex) => ({
      id: horseIndex,
      key: horseIndex,
      name: horse.name,
      gender: horse.gender,
      age: horse.age,
      height: horse.height,
      type: horse.type,
      lifeNumber: horse.lifeNumber
    }));
  };

  const createRequest = (id, type='simple') => {
    props.history.push(`/home?id=${id}&type=${type}`);
  };
  return (
    <div className='full-width'>
      <div className='invoice-root'>
        <h4 className='border-bottom-1'><Trans>Invoice Address</Trans></h4>
        <div className='row full-width text-left'>
          <div className='col-md-6'>{request.invoiceAddress.companyName}</div>
          <div className='col-md-6'>{request.invoiceAddress.phoneNumber}</div>
          <div className='col-md-6'>{request.invoiceAddress.street}</div>
          <div className='col-md-12'>{request.invoiceAddress.city}</div>
          <div className='col-md-12'>{request.invoiceAddress.country}</div>
        </div>
      </div>
      <div className='transport-group-root'>
        {
          request.transportGroups.map((group, index) => (
            <Fragment key={index}>
              <h4 className='border-bottom-1'><Trans>Transport Group</Trans></h4>
              <div className='row full-widht text-left'>

                <div class="container">
                  <div class="row">

                    <div class="col-sm">

                      <div className='col-md-6 font-weight-bold cls-1'><Trans>Source</Trans></div>
                      <div className='transferGroup-border row'>
                        {
                          Object.keys(group['pickup']).map((key2, index2) => (
                            <div className='text-left col-md-4' key={index2}>
                              <div>
                                <div className='m-10 font-weight-bold'>{t(`${key2}`)}</div>
                                <div className='m-10'>{`${group['pickup'][key2] ? t(`${group['pickup'][key2]}`) : '-'}`}</div>
                              </div>
                            </div>
                          ))
                        }
                      </div>

                    </div>

                    <div class="col-sm">

                      <div className='col-md-6 font-weight-bold cls-1'><Trans>Destination</Trans></div>

                      <div className='transferGroup-border row'>
                        {
                          Object.keys(group['destination']).map((key2, index2) => (
                            <div className='text-left col-md-4' key={index2}>
                              <div>
                                <div className='m-10 font-weight-bold'>{t(`${key2}`)}</div>
                                <div className='m-10'>{`${group['destination'][key2] ? t(`${group['destination'][key2]}`) : '-'}`}</div>
                              </div>
                            </div>
                          ))
                        }
                      </div></div>

                  </div>
                </div>
              </div>

              <div className='row full-widht'>
                <Table
                  tableName='Transport Group'
                  headings={headings}
                  rows={getRows(index)}
                />
              </div>
            </Fragment>
          ))
        }
      </div>
      <div className='table-action-root'>
        <h4 className='border-bottom-1'><Trans>Actions</Trans></h4>
        <div className='row full-width'>
          <div className='col-md-4'>
            <button type="button" onClick={() => createRequest(request.id)} className="btn btn-primary btn-block text-left create-request-button">
              <Trans>Create the same request again</Trans>
            </button>
            <button type="button" onClick={() => createRequest(request.id, 'returnRequest')} className="btn btn-primary btn-block text-left create-request-button">
              <Trans>Request return transport</Trans>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestDetails;
