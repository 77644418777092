export const transportGroupSchema = {
  pickup: {
    farmName: '',
    name: '',
    street: '',
    zipCode: '',
    city: '',
    country: 'DE',
    state: ''
  },
  destination: {
    farmName: '',
    name: '',
    street: '',
    zipCode: '',
    city: '',
    state: '',
    country: 'DE'
  },
  horses: [{
    gender: '',
    race: '',
    name: '',
    lifeNumber: '',
    age: '',
    height: '',
    type: '',
    comment: ''
  }]
};

export const horseSchema = {
  gender: '',
  race: '',
  name: '',
  lifeNumber: '',
  age: '',
  height: '',
  type: '',
  comment: ''
};

export const invoiceRequiredFields = [
  'firstname', 'lastname', 'street', 'salutation', 'zipCode', 'city', 'country', 'email'
];

export const groupRequiredFields = {
  pickup: ['name', 'street', 'zipCode', 'city', 'country'],
  destination: ['name', 'street', 'zipCode', 'city', 'country'],
  horses: ['race']
};
