import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { getUser, isLogin } from 'helpers/auth';

import { globalLoading } from 'store/modules/index';
import { Collapsible } from 'components/Forms/FieldArray/Collapsible';
import { useTranslation, Trans } from 'react-i18next';
import { createRequest } from 'store/modules/request/request.action';
import { msgAlert } from 'helpers/common';

const RenderData = ({ props }) => {
  const [selectedIndex, setSelectedIndex] = useState('');
  const { t } = useTranslation();

  const changeIndex = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex('');
    } else {
      setSelectedIndex(index);
    }
  };
  return (
    <Fragment>
      <label><Trans>Invoice Information</Trans></label>
      <div className="auth-wrapper">
        <div className="steps-inner">
          <div className='invoice-border row full-width'>
            {
              Object.keys(props.values.invoiceAddress).map((key, index) => (
                <div className='col-md-4' key={index}>
                  <div>
                    <div className='m-10 font-weight-bold'>{t(`${key}`)}</div>
                    <div className='m-10'>{`${props.values.invoiceAddress[key] ? t(`${props.values.invoiceAddress[key]}`) : '-'}`}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      {
        props.values.transportGroups.map((group, index) =>
          (
            <div className='m-t-p-2' key={index}>
              <label><Trans>Transport Group</Trans> {index + 1}</label>
              <div className="auth-wrapper">
                <div className="steps-inner">
                  <div className='full-width'>
                    {Object.keys(group).map((key1, index1) =>
                      key1 !== 'horses' && key1 !== 'periodStart' && key1 !== 'periodEnd' &&
                        key1 !== 'id' && key1 !== 'createdAt' && key1 !== 'updatedAt' ? (
                          <Collapsible key={index1} index={`${index}-${index1}-${key1}`} selectedIndex={selectedIndex} componentList={t(`${key1}`)} changeIndex={changeIndex}>
                            <div className='transferGroup-border row full-width'>
                              {
                                Object.keys(group[key1]).map((key2, index2) => (
                                  <div className='text-left col-md-4' key={index2}>
                                    <div>
                                      <div className='m-10 font-weight-bold'>{t(`${key2}`)}</div>
                                      <div className='m-10'>{`${group[key1][key2] ? t(`${group[key1][key2]}`) : '-'}`}</div>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </Collapsible>
                        ) : key1 !== 'periodStart' && key1 !== 'periodEnd' &&
                        key1 !== 'id' && key1 !== 'createdAt' && key1 !== 'updatedAt' && (
                          group[key1].map((horse, hIndex) =>
                            <Collapsible key={hIndex} index={`Trans - ${hIndex} - ${index}`} selectedIndex={selectedIndex} componentList={`${t('Horse')} # ${hIndex + 1}`} changeIndex={changeIndex}>
                              <div className='transferGroup-border row full-width'>
                                {Object.keys(horse).map((horseKey, horseIndex) => (
                                  <div className='text-left col-md-4' key={horseIndex}>
                                    <div>
                                      <div className='m-10 font-weight-bold'>{t(`${horseKey}`)}</div>
                                      <div className='m-10'>{horse[horseKey] ? t(`${horse[horseKey]}`) : '-'}</div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Collapsible>
                          )))}
                  </div>
                </div>
              </div>
            </div>
          ))
      }
    </Fragment>
  );
};

RenderData.propTypes = {
  props: PropTypes.object,
  values: PropTypes.object
};

export class ReviewConf extends Component {
  static propTypes = {
    values: PropTypes.object,
    dispatch: PropTypes.func,
    disableStepsNavigation: PropTypes.func
  }

  isValidated() {

    const { values, dispatch, disableStepsNavigation, t, setFieldValue } = this.props;
    const data = {
      data: values
    };
    if (isLogin()) {
      data.data.customer = {
        email: getUser().email
      };
    } else {
      data.data.customer = {
        email: data.data && data.data.invoiceAddress && data.data.invoiceAddress.email ? data.data.invoiceAddress.email : ''
      };
    }

    function resetValues(){
      for(let i=0; i<values.transportGroups.length; i=i+1){
        setFieldValue('invoiceAddress.street', values.invoiceAddress.addressLine1);
        setFieldValue(`transportGroups.${i}.periodStart`, new Date(values.transportGroups[i].periodStart));
        setFieldValue(`transportGroups.${i}.periodEnd`, new Date(values.transportGroups[i].periodEnd));
      }
    }

    async function createRequestFunc(data) {
      dispatch(globalLoading(true));
      try {
        const response = await dispatch(createRequest(data));
        if (response && response.value &&
          response.value.data &&
          response.value.data.msg) {
          msgAlert('success', t(response.value.data.msg), t);
          dispatch(globalLoading(false));
          disableStepsNavigation();
          return true;
        } else {
          dispatch(globalLoading(false));
          resetValues();
          msgAlert('failure', t('Unable to process your request'), t);
          return false;
        }
      } catch (err) {
        dispatch(globalLoading(false));
        resetValues();
        msgAlert('failure', t('Unable to process your request'), t);
        return false;
      }
    }
    return createRequestFunc(data);
  };

  render() {
    return (
      <Fragment>
        <RenderData props={{ ...this.props }} />
      </Fragment>
    );
  }
}

export default ReviewConf;
