export function authlogin (data) {
  localStorage.setItem('user', JSON.stringify(data.user));
  localStorage.setItem('token', data.token);
}

export function logout () {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

export function getUser () {
  if (isLogin()) {
    const data = localStorage.getItem('user');
    return JSON.parse(data);
  }
}
export function getToken () {
  if (isLogin()) { return localStorage.getItem('token'); }
}

export function isLogin () {
  const token = localStorage.getItem('token');
  return token;
}
