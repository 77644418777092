import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Field, ErrorMessage } from 'formik';

import PropTypes from 'prop-types';

const InputField = ({
  type,
  placeholder,
  name,
  label,
  required
}) => {

  const { t } = useTranslation();
  return (
    <>
      <label><Trans>{label}</Trans>
      { required && <span className='required-field'>*</span>}
      </label>
      <Field type={type} className="form-control" name={name} placeholder={t(placeholder)} />
      <ErrorMessage name={name}>{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
    </>
  )
};

InputField.propTypes = {
  label: PropTypes.any,
  name: PropTypes.any,
  placeholder: PropTypes.any,
  type: PropTypes.any
};

export default InputField;
