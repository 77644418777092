import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';
import { getCurrentUser } from 'store/modules/user/user.action';

import { isLogin } from '../helpers/auth';

const GuestRoute = ({
  component: Component,
  ...rest
}) => {
  const dispatch = useDispatch();
  if (isLogin()) {
    try {
      dispatch(getCurrentUser());
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        return <Component {...props} />;
      }}
    />
  );
};

GuestRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any
};

export default GuestRoute;
