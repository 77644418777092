/* eslint-disable linebreak-style */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const RoleDataField = ({ name, content }) => (
  <Fragment>
    {typeof content === 'string' && <td className={name === 'action' ? 'text-right table-actions' : ''} key={name}>{content}</td>}
    {typeof content !== 'string' &&
      (
        <td className={name === 'action' ? 'text-right table-actions' : ''} key={name}>
          {_.map(content, (cont) => (
            <span className="c-blue f-13 m-r-15 display-inline-block">{cont}</span>
          ))}
        </td>
      )}
  </Fragment>
);

RoleDataField.propTypes = {
  name: PropTypes.any,
  content: PropTypes.any
};

const GeneralDataField = ({ name, content }) => (<td className={name === 'action' ? 'text-right table-actions' : ''} key={name}>{content}</td>);

GeneralDataField.propTypes = {
  name: PropTypes.any,
  content: PropTypes.any
};

export class Table extends Component {
  static propTypes = {
    tableName: PropTypes.string,
    headings: PropTypes.object,
    rows: PropTypes.array,
    editComponent: PropTypes.func,
    deleteComponent: PropTypes.func,
    showCheck: PropTypes.bool,
    search: PropTypes.string,
    handleChange: PropTypes.func,
    allChecked: PropTypes.bool
  }

  render () {
    const { headings, rows } = this.props;
    let IsVisited = false;
    const getHeadingNode = (heading, key) => (
      <th key={key}>
        {heading}
      </th>
    );

    return (
      <div className=" full-width">
        <div className="table-responsive table_detail check_tbl bg-white  ">
          <table className="table table-hover">
            <thead>
              <tr>
                {_.map(headings, getHeadingNode)}

              </tr>
            </thead>

            <tbody>
              {_.map(rows, (row, index) => (

                <tr key={row.key}>

                  {_.map(_.omit(headings, 'key'), (heading, key) => {
                    IsVisited = true;
                    return (
                      <Fragment>
                        <GeneralDataField name={key} content={row[key]} />
                      </Fragment>
                    );
                  })}
                </tr>
              ))}
              {(rows.length === 0 || !IsVisited) &&
                (
                  <div >
                    <p className=" font-weight-bold full-width m-t-30 "> No Data Found </p>
                  </div>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
