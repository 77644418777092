import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { connect } from 'react-redux';
import { getRequestList } from 'store/modules/request/request.action';
import { CustomizedExpansionPanels } from 'components/Forms/FieldArray/TableCollapsible';
import Details from './RequestDetails';

RequestList.propTypes = {
  getRequestList: PropTypes.func,
  requests: PropTypes.array
};

function RequestList (props) {
  const [selectedIndex, setIndex] = useState(-1);
  // const { previousRequests } = props;

  const changeIndex = (index) => {
    if (index !== selectedIndex) {
      setIndex(index);
    } else {
      setIndex(-1);
    }
  };
  return (
    <div className='requests-header'>
      <h4>
        <Trans>
        Previous Requests
        </Trans>
      </h4>
      <div className='width-95-p'>
        {props.requests && props.requests.map((req, index) => (
          <div key={index}>
            <CustomizedExpansionPanels
              changeIndex={changeIndex}
              componentList={req.id}
              index={index}
              selectedIndex={selectedIndex}
            >
              <Details request={req} {...props}/>
            </CustomizedExpansionPanels>
          </div>
        ))}
      </div>
    </div>
  );
}

export default connect(null, { getRequestList })(RequestList);
