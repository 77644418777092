export function deformatRequests (requests) {
  const formattedRequest = requests;
  formattedRequest.forEach(request => {
    request.invoiceAddress.street = request.invoiceAddress.addressLine1;
    request.invoiceAddress.secondaryCompanyName = request.invoiceAddress.addressLine2;
    request.transportGroups.forEach(group => {
      group.periodStart = '';
      group.periodEnd = '';
      group.horses.forEach(horse => {
        if (horse.age) horse.age = Number(horse.age);
        if (horse.height) horse.height = Number(horse.height);
      });
    });
  });
  return formattedRequest;
}

export function deformatUser (user) {
  if(user){
    user.phoneNumber = Number(user.phoneNumber);
    return user;
  }
}
