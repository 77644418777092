import { sweetToast } from 'helpers/common';
import { invoiceRequiredFields, groupRequiredFields } from 'helpers/contentHelper';

let errorObj = {
  key: '',
  index: null,
  msg: ''
}

function checkProperties (obj, schema, index, showScheme=true) {
  let flag = true;
  for (var key in obj) {
    if (groupRequiredFields[schema] && groupRequiredFields[schema].includes(key) && obj[key] === '') {
      return {key: (schema==='pickup' || schema==='destination') ? 'Transport Group' : 'Horse', index, msg:`${showScheme ? schema : ''} ${key} is required`}
    }
  }
  return flag;
}

export function validateInvoiceSchema (values) {
  let flag = true;
  let key = '';
  Object.keys(values.invoiceAddress).forEach((inv, index) => {
    if ((!values.invoiceAddress[inv] || values.invoiceAddress[inv] === '') && flag && invoiceRequiredFields.includes(inv) && inv !== 'transportGroups') {
      flag = false;
      key = inv;
    }
  });
  if (!flag) {
    errorObj = { msg: `${key} is required` };
    return errorObj;
  }
    // sweetToast('error', `${key} is required.`); return false; }
  if (!(/\d/.test(values.invoiceAddress.street))) {
    errorObj = {msg: 'Street should contain number.'};
    // sweetToast('error', 'Street should contain number.');
    return errorObj;
  }

  return true;
}

export function validateGroupSchema (values) {
  let flag = true;
  let streetFlag = true;
  let streetFlagIndex = null;
  values.transportGroups.map((group, index) => {
    if (typeof flag==='boolean' && flag===true) {
      Object.keys(group).forEach(key => {
        // eslint-disable-next-line
        if (typeof flag==='boolean' && flag===true && (!group[key] || (typeof key === 'string' || key instanceof String) && group[key] === '')) {
          flag = false;
          errorObj = { key: "Transport Group", index: index, msg: `${key} is required`};
        }
        if (typeof flag==='boolean' && flag===true && key !== 'horses') {
          flag = checkProperties(group[key], key, index);
        } else if (typeof flag==='boolean' && flag===true && key === 'horses') {
          // eslint-disable-next-line
          group[key].map((horse) => {
            flag = checkProperties(horse, key, index, false);
          });
        }
        if(typeof flag!=='boolean') {
          errorObj=flag;
        }
      });
    }
    if (typeof flag==='boolean' && flag===true && streetFlag) {
      if (!(/\d/.test(group.pickup.street))) {
        streetFlagIndex = index + 1;
        streetFlag = false;
        errorObj = {key: 'Pickup', index: streetFlagIndex, msg: 'Street should contain number.', status: true};
        // sweetToast('error', `Pickup # ${streetFlagIndex} Street should contain number.`);
      } else if (!(/\d/.test(group.destination.street))) {
        streetFlagIndex = index + 1;
        streetFlag = false;
        errorObj = {key: 'Destination', index: streetFlagIndex, msg: 'Street should contain number.', status: true};
        // sweetToast('error', `Destination # ${streetFlagIndex} Street should contain number.`);
      }
    }
    return 0;
  });
  if ((typeof flag==='boolean' && !flag) || (!typeof flag==='boolean' && flag.key)) {
    return (errorObj);
  }
  return flag;
}

export function validateAddresses (values , t) {
  let flag = true;
  values.transportGroups.forEach((group, index) => {
    if (flag && group.pickup.zipCode === group.destination.zipCode &&
      group.pickup.street === group.destination.street &&
      group.pickup.city === group.destination.city) {
      flag = false;
    }
  });
  if (!flag) {
    sweetToast('error', t('Pickup address and destination address cannot be same'));
  }
  return flag;
}
