import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch  } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isLogin, logout } from 'helpers/auth';
import { logoutUser } from 'store/modules/user/user.action';
import { clearRequests } from 'store/modules/request/request.action';

export function AppNavBar (props) {
  const dispatch = useDispatch();
  const [currentLanguage, setLanguage] = React.useState({ key: localStorage.getItem('i18nextLng').includes('en') ? 'English' : 'German', value: localStorage.getItem('i18nextLng').includes('en') ? 'en' : 'de' });
  const { i18n, t } = useTranslation();

  const onLogin = () => {
    props.history.push('/login');
  };

  const onLogout = () => {
    const { history } = props;
    history.push('/login');
    dispatch(clearRequests());
    dispatch(logoutUser());
    logout();
  };

  const showRequests = () => {
    props.history.push('/requests');
  };

  const changeLanguage = lng => {
    setLanguage({ key: lng === 'en' ? 'English' : 'German', value: lng });
    i18n.changeLanguage(lng);
  };

  return (
    <>
          <nav className="navbar navbar-expand-lg navbar-light navbar-fixed-top">
            <div className="container">
              <Link className="navbar-brand" to={'/home'}>{t('Horse Portal')}</Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  { isLogin() && (
                    <li className="nav-item">
                      <Link className='nav-link c-pointer' onClick={showRequests}>{t('Previous Requests')} </Link>
                      {/* <a href="javascript:;" className="nav-link c-pointer" onClick={showRequests}>{t('Previous Requests')}</a> */}
                    </li>
                  )}
                  <li className="nav-item">
                    <Link to='/imprint' className='nav-link c-pointer' target="_blank">{t('Imprint')}</Link>
                  </li>
                  <li className="nav-item">
                    <Link to='/policy' className='nav-link c-pointer' target="_blank">{t('Data Privacy')}</Link>
                  </li>
                  { !isLogin() && (
                    <li className="nav-item">
                      <Link className='nav-link c-pointer' onClick={onLogin}>{t('Login')} </Link>
                      {/* <a  href="javascript:;"className="nav-link c-pointer" onClick={onLogin}>{t('Login')}</a> */}
                    </li>
                  )}
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" id="dropdown09" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="flag-icon flag-icon-us"> </span> {currentLanguage.key}</Link>
                    <div className="dropdown-menu" aria-labelledby="dropdown09">
                    <Link className='nav-link c-pointer' onClick={() => changeLanguage('en')}><span className="flag-icon flag-icon-fr"> </span>  English </Link>
                    <Link className='nav-link c-pointer' onClick={() => changeLanguage('de')}><span className="flag-icon flag-icon-it"> </span>  German </Link>
                    </div>
                  </li>
                  { isLogin() && (
                    <li className="nav-item">
                      <Link className="nav-link c-pointer" onClick={onLogout}>{t('Logout')}</Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </nav>
    </>
  );
}

AppNavBar.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
  dispatch: PropTypes.func,
  clearRequests: PropTypes.func
};

export default AppNavBar
