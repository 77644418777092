import React, {useState} from 'react';
import {ModalBox} from 'components/UI/ModalBox';
import { useTranslation } from 'react-i18next';

function AppInfo() {
    const [showInfo, setInfo]=useState(true);
  const { i18n, t } = useTranslation();
  const [currentLanguage, setLanguage] = React.useState({ key: localStorage.getItem('i18nextLng').includes('en') ? 'English' : 'German', value: localStorage.getItem('i18nextLng').includes('en') ? 'en' : 'de' });
    console.log(currentLanguage)
    const performAction=()=>{
        setInfo(false);
      }
    
      const changeLanguage = lng => {
        setLanguage({ key: lng === 'en' ? 'English' : 'German', value: lng });
        i18n.changeLanguage(lng);
      };
    
      const content= ()=>(
          <div className='row w-120'>
          <img className='col-md-6 c-pointer h-30' alt="en" src='/static/svgs/us.svg' onClick={() => changeLanguage('en')} />
          <img className='col-md-6 c-pointer h-30' alt="de" src='/static/svgs/de.svg' onClick={() => changeLanguage('de')} />
          </div>
      )

    return (
        <div>
            {showInfo && (
        <ModalBox
          size="lg "
          actions={[t('Close')]}
          performActionItem={performAction}
          content={content}
        >
          <div>
          <p>{t('Dear customers,')}</p>
          <p>{t('The digital world has arrived here as well.')}</p><p></p>
            <p>
                {t('In order to be able to process your request quickly, we would like to ask you to fill in the following form.')}
              <br></br>
                {t('This allows us a direct further processing for route creation and costing.')}
              <br></br>
              
                {t('So you get an answer with prospect dates and prices.')}
              <br></br>
              
                {t('The data you provide is understood by us as a request, not as an order.')}
              <br></br>
              
                {t('Your data will be treated confidentially and will not be passed on to third parties.')}
              <br></br>
            </p>
            <p>
              {t('Your Müller horse transport team.')}
            </p>
          </div>
        </ModalBox>
      )}
        </div>
    );
}

export default AppInfo;