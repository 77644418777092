import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

export function DateTimePicker (props) {
  const { t } = useTranslation();
  return (
    <div>
      <DatePicker
        selected={props.selectField}
        dateFormat="yyyy-MM-dd"
        className="form-control"
        name={props.name}
        minDate={new Date()}
        onChange={date => props.setDate(date, props.name)}
        placeholderText={t('Select Date')}
      />
    </div>
  );
}

DateTimePicker.propTypes = {
  setDate: PropTypes.func,
  selectField: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string
};

export default DateTimePicker;
