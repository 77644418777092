import { GET_REQUESTS_LIST, CREATE_REQUEST } from './request.types';
import { newRequest } from 'helpers/api';
import { urls } from './request.urls';
import { formatRequest } from 'helpers/dataFormater';
import humps from 'humps';

export function createRequest(data) {
  if (formatRequest(data)) {
    console.log(data);
    const requestObject = {
      method: 'POST',
      url: urls.createRequest(),
      data: humps.decamelizeKeys(data, { split: /(?=[A-Z0-9])/ }),
    };
    return {
      type: CREATE_REQUEST,
      payload: newRequest(requestObject)
    };
  } else {
    return {
      payload: {data: {error: "failed"}}
    }
  }
}

export function getRequestList() {
  const requestObject = {
    method: 'GET',
    url: urls.getAllRequests()
  };
  return {
    type: GET_REQUESTS_LIST,
    payload: newRequest(requestObject)
  };
}

export function clearRequests() {
  return {
    type: 'CLEAR_REQUESTS'
  };
}
