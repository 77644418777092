// Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import React Scrit Libraray to load Google object
import Script from 'react-load-script';

export class SearchField extends Component {
  static propTypes = {
    onLocationSelect: PropTypes.func,
    id: PropTypes.any
  }

  // Define Constructor
  constructor (props) {
    super(props);

    // Declare State
    this.state = {
      city: '',
      query: ''
    };
  }

  handleScriptLoad = () => {
    const { id } = this.props;
    // Declare Options For Autocomplete
    // const options = {
    //   types: ['(cities)']
    // };

    // Initialize Google Autocomplete
    /* global google */ // To disable any eslint 'google not defined' errors
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(id)
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocomplete.setFields(['address_components', 'formatted_address']);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  handlePlaceSelect = () => {
    const { onLocationSelect } = this.props;
    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;
    // Check if address is valid
    if (address) {
      // Set State
      this.setState(
        {
          city: address[0].long_name,
          query: addressObject.formatted_address
        }
      );
      onLocationSelect(addressObject);
    }
  }

  render () {
    const { id, t } = this.props;
    return (
      <div>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyA8rJ9jrXHQHgbGqcTq00XemqeIhXVDC0s&libraries=places"
          onLoad={this.handleScriptLoad}
        />
        <div className='form-group'>
          <input className='form-control box-shadow' id={id} placeholder={t('Search place with Google Maps')}/>
        </div>
      </div>
    );
  }
}

export default SearchField;
