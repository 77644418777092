import React, {useState} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import CountriesField from 'components/Forms/Fields/CountriesPicker';
import { registerSchema } from 'helpers/validation';
import { useTranslation, Trans } from 'react-i18next';
import { register } from 'store/modules/user/user.action';
import { msgAlert, ifKeyExist } from 'helpers/common';
import { globalLoading } from 'store/modules/index';
import { ReCaptcha } from 'react-recaptcha-v3';
import { verifyRecaptchaToken } from 'store/modules/raCaptcha/actions';
import {publicKey, privateKey} from 'helpers/private/PRIVATE_KEYS';
import InputField from 'components/UI/InputField';

let formikErrors = {};
export const RegisterForm = (props) => {
  const { t } = useTranslation();
  const [isValidate, setValidate]= useState(false);
  const [values, setValues]= useState({});

  const verifyCallback= async (recaptchaToken) => {
    const { verifyRecaptchaToken, globalLoading } = props;
    globalLoading(true);
    let res = await verifyRecaptchaToken(privateKey, recaptchaToken);
    if (ifKeyExist(res, 'value.data.data.success')) {
      try{
        res = await props.register(values,t);
        if (res.value && res.value.data && res.value.data.isSuccess === true) {
          msgAlert('success', t('User Successfully Registered.'),t);
          props.history.push('/login');
        } else if (res.value.data.isSuccess === false) {
          const key = Object.keys(res.value.data.message)[0];
          msgAlert('failure', t(res.value.data.message[key][0]),t);
        }
      }catch(err){
        formikErrors(err.data.message);
      }
      
    }
    globalLoading(false);
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        companyName: '',
        secondaryCompanyName: '',
        salutation: '',
        street: '',
        zipCode: '',
        city: '',
        country: 'DE',
        vatId: '',
        phoneNumber: '',
        email: '',
        password: '',
        confirmPassword: ''
      }}
      validationSchema={registerSchema}
      onSubmit={ async (values, { setErrors }) => {
        formikErrors =  setErrors;
        console.log(values)
        setValues(values);
        setValidate(true);
        setValidate(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form className="m-t-20">
          <h3><Trans>Sign Up</Trans></h3>
          <div className='row'>
            <div className="col-md-4 form-group">
               <InputField label='First Name' type='text' name="firstName" placeholder={'First Name'} required/>
            </div>

            <div className="col-md-4 form-group">
            <InputField label='Last Name' type='text' name="lastName" placeholder={'Last Name'} required/>
            </div>

            <div className="col-md-4 form-group">
              <InputField label='Company Name' type='text' name="companyName" placeholder={'Company Name'}/>
            </div>

            <div className="col-md-4 form-group">
              <InputField label='Secondary Company Name' type='text' name="secondaryCompanyName" placeholder={'Secondary Company Name'}/>
            </div>

            <div className="col-md-4 form-group">
                <label><Trans>Salutation</Trans><span className='required-field'>*</span></label>
                <Field as='select' type='text' className='form-control' name='salutation' placeholder={t('Salutation')}>
                  <option value="">--- {t('Select')} ---</option>
                  <option value='Mr.'>{t('Mr.')}</option>
                  <option value='Ms.'>{t('Ms.')}</option>
                </Field>
                <ErrorMessage  name="salutation">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
            </div>

            <div className="col-md-4 form-group">
              <InputField label='Street' type='text' name="street" placeholder={'Street'} required/>
            </div>

            <div className="col-md-4 form-group">
              <InputField label='Zip-Code' type='text' name="zipCode" placeholder={'Zip-Code'} required/>
            </div>

            <div className="col-md-4 form-group">
              <InputField label='City' type='text' name="city" placeholder={'City'} required/>
            </div>

            <div className="col-md-4 form-group">

              <label><Trans>Country</Trans><span className='required-field'>*</span></label>
              <CountriesField name='country'/>
              {/* <Field type="text" className="form-control" name="country" placeholder={t('Country')} /> */}
              <ErrorMessage  name="country">{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
            </div>

            <div className="col-md-4 form-group">
              <InputField label='VAT-ID' type='text' name="vatId" placeholder={'VAT-ID'}/>
            </div>

            <div className="col-sm-4 form-group">
              <InputField label='Phone Number' type='number' name="phoneNumber" placeholder={'Phone Number'} required/>
            </div>

            <div className="col-md-4 form-group">
              <InputField label='Email Address' type='email' name="email" placeholder={'Email Address'} required/>
            </div>

            <div className="col-md-4 form-group">
              <InputField label='Password' type='password' name="password" placeholder={'Password'} required/>
            </div>

            <div className="col-md-4 form-group">
              <InputField label='Confirm Password' type='password' name="confirmPassword" placeholder={'Confirm Password'} required/>
            </div>
          </div>
          <div className='row'>
            <div className='register-button'>
              <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}><Trans>Sign Up</Trans></button>
            </div>

          </div>
          <p className="forgot-password text-right">
            <Trans>Already registered</Trans>? <Link to={'/login'}><Trans>sign in</Trans></Link>
          </p>
          {isValidate && (
            <ReCaptcha
              sitekey={publicKey}
              action="LoginPage"
              verifyCallback={verifyCallback}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

RegisterForm.propTypes = {
  register: PropTypes.func,
  history: PropTypes.any,
  globalLoading: PropTypes.func
};

export default connect(null, { register, globalLoading, verifyRecaptchaToken })(RegisterForm);
