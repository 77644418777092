const translations = {
  'instruction heading': 'Instructions',
  'Welcome to horsePortal': 'Welcome to our new customer portal! Please run through the following wizard. Your Transport Request is free and non-committal. We will use your invoiceaddress for the offer. Your request may contain multiple transport groups with multiple horses per transport group. If you would like to request a transport to and from a location, use two transport groups for this as each transport group can only have one pickup address and one destination address.',
  details: 'We are looking forward to your inquiry and will be well prepared to advise you!',

  'Horse Portal': 'Pferdetransporte Müller',
  'Company Name': 'Company Name',
  'First name': 'First name',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'Secondary Company Name': 'Alternative Company Name',
  Street: 'Street',
  'Zip-Code': 'Zip-Code',
  City: 'City',
  Country: 'Country',
  'Vat-ID': 'Vat-ID',
  'Email Address': 'Email Address',
  'Farm name': 'Farm name',
  Name: 'Name',
  Gender: 'Gender',
  Race: 'Race',
  Age: 'Age',
  Height: 'Height',
  Type: 'Type',
  Remarks: 'Remarks',
  'Add Group': 'Add Group',
  'Delete Group': 'Delete Group',
  'Create Request Group': 'Transport Groups',
  'Transport Group': 'Transport Group',
  'Pick up Address': 'Pick up Address',
  'Destination Address': 'Destination Address',
  'Horse Info': 'Horses',
  'Invoice Address': 'Invoice Address',
  'Invoice Information': 'Invoice Information',
  'Search place with Google Maps': 'Search place with Google Maps',
  Instructions: 'Instructions',
  Review: 'Review',
  Submit: 'Submit',
  Next: 'Next',
  Back: 'Back',
  Logout: 'Logout',
  Required: 'Required',
  'Tranport Group':'Tranport Group',
  'Invalid email address':'Invalid email address',
  'Your request has been submitted.': 'Your request has been submitted.',
  'Select Date': 'Select Date',
  'Sign In': 'Sign In',
  'Enter Email': 'Enter Email',
  Password: 'Password',
  'Remember me': 'Remember me',
  'Dont have any account': 'Dont have any account',
  'Register here': 'Register here',
  'Create Request': 'Create Request',
  'Confirm Password': 'Confirm Password',
  'Already registered': 'Already registered',
  'sign in': 'Sign in',
  'Sign Up': 'Sign Up',

  firstname: 'First Name',
  lastname: 'Last Name',
  Company: 'Company',
  companyName: 'Company',
  secondaryCompanyName: 'Secondary Company',
  zipCode: 'Zip Code',
  vatId: 'Vat ID',
  phoneNumber: 'Phone No.',
  email: 'Email Address',
  farmName: 'Farm Name',
  name: 'Name',
  street: 'Street',
  city: 'City',
  country: 'country',
  gender: 'Gender',
  race: 'Race',
  lifeNumber: 'EQ-ID',
  age: 'Age',
  height: 'Height',
  type: 'Type',
  comment: 'Remarks',
  pickup: 'Pick Up Info',
  destination: 'Destination Info',
  Login: 'Login',
  'Previous Requests': 'Previous Requests',

  // 'Create new transport based on this one': 'Create new transport based on this one',
  'Create the same request again': 'Create the same request again',
  'Request return transport': 'Request return transport',
  Actions: 'Actions',
  Destination: 'Destinations',
  Source: 'Source',
  'As of Date': 'As of Date',
  'Up To Date': 'Up to Date',
  Telephone: 'Telephone',
  'Alternative Telephone': 'Telephone alternative',
  'Add Horse': 'Add Horse',
  'Delete Horse': 'Delete Horse',
  Salutation: 'Salutation',
  salutation: 'salutation',
  'Mr.': 'Mr.',
  'Ms.': 'Ms.',
  'Problematic horse': 'Problematic horse',
  'Saddle horse': 'Saddle horse',
  'Raw horse': 'Raw horse',
  'Foal horse': 'Foal horse',
  'Record created successfully.': 'Request has been submitted, we will soon get in touch with you.',
  Imprint: 'Imprint',
  'Data Privacy': 'Data Privacy',
  'Login As Guest': 'Login As Guest',
  'Select Template': 'Select Template',
  'Street should contain number.': 'Street should contain number.',
  'Reset': 'Reset',
  'Forgot Password': 'Forgot Password',
  'firstname is required': 'Firstname is required',
  'lastname is required': 'Lastname is required',
  'name is required': 'Name is required',
  'street is required': 'Street is required',
  'zipCode is required': 'Zip-Code is required',
  'city is required': 'City is required',
  'country is required': 'Country is required',
  'Atleast 8 characters':'Atleast 8 characters',
  'Passwords must match':'Passwords must match',
  'salutation is required':'salutation is required',
  'pickup name is required': 'Name is required',
  'pickup street is required': 'Street is required',
  'pickup zipCode is required': 'Zip-Code is required',
  'pickup city is required': 'City is required',
  'pickup country is required': 'Country is required',

  'destination name is required': 'Name is required',
  'destination street is required': 'Street is required',
  'destination zipCode is required': 'Zip-Code is required',
  'destination city is required': 'City is required',
  'destination country is required': 'Country is required',
  'email is required': 'Email is required',
  'Pickup address and destination address cannot be same': 'Pickup address and destination address cannot be same',
  'type is required': 'Type is required',
  'Dear customers,': 'Dear customers,',
  'The digital world has arrived here as well.': 'The digital world has arrived here as well.',
  'In order to be able to process your request quickly, we would like to ask you to fill in the following form.': 'In order to be able to process your request quickly, we would like to ask you to fill in the following form.',
  'This allows us a direct further processing for route creation and costing.': 'This allows us a direct further processing for route creation and costing.',
  'So you get an answer with prospect dates and prices.': 'So you get an answer with prospect dates and prices.',
  'The data you provide is understood by us as a request, not as an order.': 'The data you provide is understood by us as a request, not as an order.',
  'Your data will be treated confidentially and will not be passed on to third parties.': 'Your data will be treated confidentially and will not be passed on to third parties.',
  'Your Müller horse transport team.': 'Your Müller horse transport team.',
  'Close': 'Close',
  'Street should contain a number': 'Street should contain a number',
  'periodStart is required': 'Period Start is required',
  'periodEnd is required': 'Period End is required',
  'Stallion': 'Stallion',
  'Gelding': 'Gelding',
  'Mare': 'Mare',
  'Select': 'Select',
  'Malicious activity found':'Malicious activity found',
  'Reset Password':'Reset Password',
  'Password Changed Successfully':'Password Changed Successfully',
   'Thanks for signing up! You can login now':'Thanks for signing up! You can login now',
   'Invalid Token':'Invalid Token',
   'User Successfully Registered.':'User Successfully Registered.',
   "Login Here":"Login Here",
   "Success!":"Success!",
   "Failed!":"Failed!",
   "Phone Number":"Phone Number",

   "Token is Expired. Please log in":"Token is Expired. Please log in",
   'Unable to login with provided credentials.':'Unable to login with provided credentials.',
   'Enter password':'Enter Password',
   'phoneNumber1':'phoneNumber1',
   'phoneNumber2':'phoneNumber2',
   'Home':'Home',
   'Data fetching failure':'Data fetching failure',
   "Please confirm your email to login.":'Please confirm your email to login.',
   "This password is too short. It must contain at least 8 characters.":"This password is too short. It must contain at least 8 characters.",
   "This password is too common.":"This password is too common.",
   "This password is entirely numeric.":"The password consists of numbers only.Please also use letters and special characters",
   "The password reset link was invalid, possibly because it has already been used.Please request a new password reset.":"The password reset link was invalid, possibly because it has already been used.Please request a new password reset.",
    "The password is too similar to the email address.":"The password is too similar to the email address.",
    "The password is too similar to the old one":"the password is too similar to the old one",
    " race is required": " race is required",

  'We\'ve emailed you instructions for setting your password, if an account exists with the email you entered.You should receive them shortly.':'We\'ve emailed you instructions for setting your password, if an account exists with the email you entered.You should receive them shortly'
};

export default translations;
