import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '93%',
    marginTop: 5,
    marginLeft: '3%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    textAlign: 'left',
    fontWeight: 'fontWeightRegular'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  expansionDetails: {
    backgroundColor: 'rgba(0, 0, 0, .03)'
  }
}));

Collapsible.propTypes = {
  children: PropTypes.element,
  index: PropTypes.string,
  selectedIndex: PropTypes.string,
  componentList: PropTypes.any,
  changeIndex: PropTypes.func,
  DeleteGroup: PropTypes.element,
  arrayHelpers: PropTypes.any,
  showDelete: PropTypes.bool
};

export function Collapsible (props) {
  const classes = useStyles();
  const { children, index, selectedIndex, componentList, changeIndex, DeleteGroup, arrayHelpers, showDelete } = props;

  return (
    <div className={classes.root}>
      <ExpansionPanel elevation={2} expanded={selectedIndex === index} onChange={() => { setTimeout(() => { changeIndex(index); }); }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id={index}
        >
          <div className='row full-width'>
            <div className='col-md-6 text-left'>
              <Typography className={classes.heading}>{componentList}</Typography>
            </div>
            {DeleteGroup && showDelete && (
              <div className='col-md-6 text-right'>
                <DeleteGroup arrayHelpers1={arrayHelpers} index={index} />
              </div>
            )}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default Collapsible;
