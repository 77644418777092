import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiExpansionPanelDetails);

CustomizedExpansionPanels.propTypes = {
  children: PropTypes.element,
  index: PropTypes.string,
  selectedIndex: PropTypes.string,
  componentList: PropTypes.any,
  changeIndex: PropTypes.func
};

export function CustomizedExpansionPanels (props) {
  const { children, index, selectedIndex, componentList, changeIndex } = props;

  return (
    <div>
      <ExpansionPanel elevation={2} square expanded={selectedIndex === index} onChange={() => { setTimeout(() => { changeIndex(index); }); }}>
        <ExpansionPanelSummary aria-controls="panel1d-content" id={index}>
          <Typography>Transport # {componentList}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default CustomizedExpansionPanels;
