import React, { useEffect } from 'react';

import RegisterForm from '../components/Client/Authentication/RegisterForm';
import {publicKey} from 'helpers/private/PRIVATE_KEYS';
import { loadReCaptcha } from 'react-recaptcha-v3';
import AppNavBar from 'components/App/AppNavBar';

export function Register (props) {

  useEffect(() => {
    loadReCaptcha(publicKey);
  }, []);


  return (
    <>
      <div className='background-root'>
        <div className="App">
        <AppNavBar {...props}></AppNavBar>
        <div className="auth-wrapper">
            <div className="auth-register">
              <RegisterForm {...props}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
