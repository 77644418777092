const translations = {
  "instruction heading": "Anleitung",
  "Welcome to horsePortal":
    "Herzlich willkommen im neuen Anfrageportal! Bitte führen Sie die folgenden Schritte durch, um uns eine Transportanfrage zu übersenden. Eine Anfrage ist kostenlos und unverbindlich, die Rechnungsanschrift wird nur für das Angebot erfasst. Ihre Anfrage kann mehrere Transportgruppen mit jeweils mehreren Pferden beinhalten. Eine Transportgruppe hat exakt eine Abholadresse und exakt eine Zieladresse. Wenn Sie unterschiedliche Abhol oder Zieladressen haben (z.B. Hin- und Rücktransport), können Sie dazu verschiedene Transportgruppen anlegen.",
  "details": "Wir freuen uns auf Ihre Anfrage!",

  "Horse Portal": "Pferdetransporte Müller",
  "Company Name": "Firmenname",
  "First Name": "Vorname",
  "Last Name": "Nachname",
  "Secondary Company Name": "Zusätzlicher Firmenname",
  "Street": "Straße",
  "Zip-Code": "Postleitzahl",
  "Country": "Land",
  "Vat-ID": "Vat-ID",
  "City": "Stadt",

  "Email Address": "E-Mail-Addresse",
  "Farm name": "Hofname",
  "Name": "Name",
  "Gender": "Geschlecht",
  "Race": "Rasse",
  "Age": "Alter",
  "Height": "Höhe",
  "Type": "Art",
  "Remarks": "Bemerkungen",
  "Add Group": "Gruppe hinzufügen",
  "Delete Group": "Gruppe löschen",
  "Create Request Group": "Transportgruppen",
  "Transport Group": "Transportgruppe",
  "Pick up Address": "Abholadresse",
  "Destination Address": "Zieladresse",
  "Horse Info": "Pferd Info",
  "Horse": "Pferd",
  "Invoice Address": "Rechnungsanschrift",
  "Invoice Information": "Rechnungsinformationen",
  "Search place with Google Maps": "Ort mit Google Maps suchen",
  "Instructions": "Anleitung",
  "Review": "Angaben überprüfen",
  "Submit": "Absenden",
  "Next": "Weiter",
  "Back": "Zurück",
  "Logout": "Ausloggen",
  "Required": "Erforderlich",
  "Tranport Group":"Transportgruppe",
  "Invalid email address":"Ungültige E-Mail-Adresse",
  "Your request has been submitted.": "Ihre Anfrage wurde übermittelt.",
  "Select Date": "Datum auswählen",
  "Sign In": "Anmelden",
  "Enter Email": "Email eingeben",
  "Password": "Passwort",
  "Remember me": "Eingeloggt bleiben",
  "Dont have any account": "Haben Sie noch keinen Account",
  "Register here": "Hier registrieren",
  "Create Request": "Anfrage erstellen",
  "Confirm Password": "Passwort bestätigen",
  "Already registered": "Bereits registriert",
  "sign in": "Anmelden",
  "Sign Up": "Registrieren",
  "Phone Number": "Telefonnummer",

  "firstname": "Vorname",
  "lastname": "Nachname",
  "CompanyName": "Firmenname",
  "companyName": "Firmenname",
  "secondaryCompanyName": "Zusätzlicher Firmenname (Falls vorhanden)",
  "zipCode": "Postleitzahl",
  "vatId": "Vat ID",
  "phoneNumber": "Rufnummer",
  "email": "E-Mail-Addresse",
  "Hofname": "Hofname",
  "name": "Name",
  "street": "Straße",
  "city": "Stadt",
  "state": "Bundesland",
  "country": "Land",
  "gender": "Geschlecht",
  "race": "Rasse",
  "lifeNumber": "EQ-ID",
  "age": "Alter",
  "height": "Höhe",
  "type": "Art",
  "comment": "Bemerkungen",
  "pickup": "Abholadresse",
  "destination": "Zieladdresse",
  "Login": "Einloggen",
  "Previous Requests": "Vorherige Anfragen",

  
  "Create the same request again": "Nochmal anfragen",
  "Request return transport": "Rücktransport anfragen",
  "Actions": "Aktionen",
  "Destination": "Ziel",
  "Source": "Quelle",
  "As of Date": "Transport möglich ab",
  "Up To Date": "Transport möglich bis",
  "Telephone": "Telefon",
  "Alternative Telephone": "Telefon alternativ",
  "Add Horse": "Pferd hinzufügen",
  "Delete Horse": "Pferd löschen",
  "Salutation": "Anrede",
  "salutation": "Anrede",
  "Mr.": "Herr",
  "Ms.": "Frau",
  "Problematic horse": "Problempferd",
  "Saddle horse": "Reitpferd",
  "Raw horse": "Rohes Pferd",
  "Foal horse": "Fohlen",
  "Record created successfully.": "Anfrage abgesendet! Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.",
  "Imprint": "Impressum",
  "Data Privacy": "Datenschutz",
  "Login As Guest": "Als Gast fortfahren",
  "Select Template": "Vorlage auswählen",
  "Street should contain number.": "Straße sollte eine Hausnummer enthalten.",
  "Reset": "Zurücksetzen",
  "Forgot Password": "Passwort vergessen",
  "firstname is required": "Vorname ist erforderlich",
  "lastname is required": "Nachname ist erforderlich",
  "name is required": "Name ist erforderlich",
  "street is required": "Straße ist erforderlich",
  "zipCode is required": "Postleitzahl ist erforderlich",
  "city is required": "Stadt ist erforderlich",
  "country is required": "Land ist erforderlich",
  "email is required": "E-Mail-Addresse ist erforderlich",
  "Atleast 8 characters":"Mindestens 8 Zeichen",
  "salutation is required":"Das Feld Anrede ist erforderlich",
  "Passwords must match":"Die Passwörter müssen übereinstimmen",
  "pickup name is required": "Name unter Abholadresse ist erforderlich",
  "pickup street is required": "Straße unter Abholadresse ist erforderlich",
  "pickup zipCode is required": "Postleitzahl unter Abholadresse ist erforderlich",
  "pickup city is required": "Stadt unter Abholadresse ist erforderlich",
  "pickup country is required": "Land unter Abholadresse ist erforderlich",
  " race is required": "Rasse ist erforderlich",

  "destination name is required": "Name unter Zieladresse ist erforderlich",
  "destination street is required": "Straße unter Zieladresse ist erforderlich",
  "destination zipCode is required": "Postleitzahl unter Zieladresse ist erforderlich",
  "destination city is required": "Stadt unter Zieladresse ist erforderlich",
  "destination country is required": "Land unter Zieladresse ist erforderlich",
  "Pickup address and destination address cannot be same": "Abholadresse und Zieladresse dürfen nicht identisch sein",
  "type is required": "Typ ist erforderlich",
  "Dear customers,": "Liebe Kunden,",
  "The digital world has arrived here as well.": "auch bei uns hat die digitale Welt Einzug gehalten.",
  "In order to be able to process your request quickly, we would like to ask you to fill in the following form.": "Damit wir Ihre Anfrage zügig bearbeiten können, möchten wir Sie bitten, das folgende Formular auszufüllen.",
  "This allows us a direct further processing for route creation and costing.": "Dieses ermöglicht uns eine direkte Weiterbearbeitung für die Routenerstellung und Kostenermittlung.",
  "So you get an answer with prospect dates and prices.": "Sie bekommen so schneller eine Antwort mit möglichen Terminen und Preisen.",
  "The data you provide is understood by us as a request, not as an order.": "Die von Ihnen angegebenen Daten werden von uns als Anfrage verstanden, nicht als Auftrag.",
  "Your data will be treated confidentially and will not be passed on to third parties.": "Ihre Daten werden von uns vertraulich behandelt und nicht an Dritte weitergegeben.",
  "Your Müller horse transport team.": "Ihr Müller Pferdetransport-Team.",
  "Close": "schließen",
  "Street should contain a number": "Straße sollte eine Nummer enthalten",
  "periodStart is required": "Bitte prüfen Sie den Transportzeitraum",
  "periodEnd is required": "Bitte prüfen Sie den Transportzeitraum",
  "farmName": "Hofname",
  "Stallion": "Hengst",
  "Gelding": "Wallach",
  "Mare": "Stute",
  "Select": "Wählen",
  "Malicious activity found":"Schädliche Aktivität gefunden",
  "Reset Password":"Passwort ändern",
  "Password Changed Successfully":"Passwort erfolgreich geändert",
  "Thanks for signing up! You can login now":"Danke für's Registrieren! Sie können sich nun anmelden.",
  "Invalid Token":"Ungültiges Token.",
  "User Successfully Registered.":"Benutzer erfolgreich registriert.",
  "Login Here":"Hier anmelden",
  "Success!":"Erfolgreich!",
   "Failed!":"Gescheitert!",

   "Token is Expired. Please log in":"Token ist abgelaufen. Bitte loggen Sie sich ein.",
   "Unable to login with provided credentials.":"Die Anmeldung ist mit den angegebenen Anmeldeinformationen nicht möglich.",
   "Enter password":"Passwort eingeben",
   "phoneNumber1":"Telefonnummer1",
   "phoneNumber2":"Telefonnummer2",
   "Home":"Start",
   "Data fetching failure":"Fehler beim Abrufen der Daten.",
   "Please confirm your email to login.":"Bitte bestätigen Sie Ihre E-Mail-Adresse, um sich anzumelden.",
   "This password is too short. It must contain at least 8 characters.":"Das Passwort ist zu kurz, es sollte aus mindestens 8 Zeichen bestehen",
   "This password is too common.":"Das Passwort ist zu simpel",
   "This password is entirely numeric.":"Das Passwort besteht nur aus Zahlen. Bitte verwenden Sie auch Buchstaben und Sonderzeichen.",

   "The password reset link was invalid, possibly because it has already been used.Please request a new password reset.":"Der Link zum Zurücksetzen Ihres Passworts ist ungültig. Haben Sie diesen Link vielleicht bereits verwendet?Bitte fordern Sie einen neuen Link an.",
   "The password is too similar to the email address.":"Das Passwort ist der E-Mail-Adresse zu ähnlich.",
   "The password is too similar to the old one":"Das Passwort ist dem vorherigen Passwort zu ähnlich",

  "We've emailed you instructions for setting your password, if an account exists with the email you entered.You should receive them shortly.":"Wir haben Ihnen eine E-Mail gesendet. Bitte klicken Sie auf den Link in der E-Mail, um ihr Passwort zurückzusetzen. Sie werden die E-Mail in Kürze erhalten. Bitte prüfen Sie auch ihren Spam-Ordner."
};

export default translations;
