import React from 'react';
import PropTypes from 'prop-types';

import { Field, ErrorMessage } from 'formik';
import { useTranslation, Trans } from 'react-i18next';

function Horses (props) {
  const { groupName, index } = props;
  const { t } = useTranslation();

  function validateField (value) {
    let error;
    if (value === '') {
      error = 'Required';
    }
    return error;
  }

  return (
    <div className='row text-left'>
      <div className="col-md-4 form-group">
        <label><Trans>Gender</Trans></label>
        <Field className="form-control" as="select" name={`${groupName}.${index}.gender`}>
          <option value="">--- {t('Select')} ---</option>
          <option value='stallion'>{t('Stallion')}</option>
          <option value='gelding'>{t('Gelding')}</option>
          <option value='mare'>{t('Mare')}</option>
        </Field>
        <ErrorMessage  name={`${groupName}.${index}.gender`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label><Trans>Race</Trans><span className='required-field'>*</span></label>
        <Field type="text" list='racesName' className="form-control" name={`${groupName}.${index}.race`} placeholder={t('Race')} validate={validateField}/>
        <datalist id="racesName">
          <option value="Islandpferd" />
          <option value="Warmblut" />
          <option value="Hannoveraner" />
          <option value="Oldenburger" />
          <option value="Esel" />
          <option value="Muli" />
          <option value="Exmoorpony" />
          <option value="Spanier" />
          <option value="PRE" />
          <option value="Vollblut" />
          <option value="Kaltblut" />
        </datalist>
        <ErrorMessage  name={`${groupName}.${index}.race`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label><Trans>Name</Trans></label>
        <Field type="text" className="form-control" name={`${groupName}.${index}.name`} placeholder={t('Name')} />
        <ErrorMessage  name={`${groupName}.${index}.name`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label>UELN</label><a className='info' href="http://www.ueln.net/ueln-presentation/what-is-the-ueln/" target="_blank" rel="noopener noreferrer"><i className="material-icons info c-pointer" title="Show CICD Guide">info</i></a>
        <Field type="text" className="form-control" name={`${groupName}.${index}.lifeNumber`} placeholder="IS201918853" />
        <ErrorMessage  name={`${groupName}.${index}.UELN`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label><Trans>Age</Trans></label>
        <Field type="number" className="form-control" min='0' max='30' name={`${groupName}.${index}.age`} placeholder={t('Age')} />
        <ErrorMessage  name={`${groupName}.${index}.age`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label><Trans>Height</Trans>(in cm)</label>
        <Field type="number" className="form-control" min='0' max='200' name={`${groupName}.${index}.height`} placeholder={t('Height')} />
        <ErrorMessage  name={`${groupName}.${index}.height`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label><Trans>Type</Trans></label>
        <Field className="form-control" as="select" name={`${groupName}.${index}.type`} >
          <option value="">--- Select ---</option>
          <option value="problem_horse">{t('Problematic horse')}</option>
          <option value="saddle_horse">{t('Saddle horse')}</option>
          <option value="raw_horse">{t('Raw horse')}</option>
          <option value='foal'>{t('Foal horse')}</option>
        </Field>
        <ErrorMessage  name={`${groupName}.${index}.type`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label><Trans>Remarks</Trans></label>
        <Field type="text" className="form-control" name={`${groupName}.${index}.comment`} placeholder={t('Remarks')} />
      </div>
    </div>
  );
}

Horses.propTypes = {
  groupName: PropTypes.string,
  index: PropTypes.number
};

export default Horses;
