import { LOGIN_USER, REGISTER_USER, CURRENT_USER, LOGOUT_USER, RESET_PASSWORD, RESET_PASSWORD_CONFIRM, ACTIVATE_USER } from './user.types';
import { urls } from './user.urls';
import { newRequest } from 'helpers/api';
import { getLanguage } from 'helpers/common';
import humps from 'humps';

export function login (data,t) {
  const requestObject = {
    method: 'POST',
    url: urls.login(),
    data: data,
    t
  };
  return {
    type: LOGIN_USER,
    payload: newRequest(requestObject)
  };
}

export function register (data,t) {
  const lang= getLanguage()
  const requestObject = {
    method: 'POST',
    url: urls.register(lang),
    data: {
      user: humps.decamelizeKeys(data)
    },
    t
  };
  return {
    type: REGISTER_USER,
    payload: newRequest(requestObject)
  };
}

export function resetPassword (email) {
  const lang= getLanguage()
  const requestObject = {
    method: 'POST',
    url: urls.resetPassword(),
    data: {
    lang,
    email
    }
  };
  return {
    type: RESET_PASSWORD,
    payload: newRequest(requestObject)
  };
}

export function resetPasswordConfirm (data) {
  data.lang = getLanguage()
  const requestObject = {
    method: 'POST',
    url: urls.resetPasswordConfirm(),
    data: data
  };
  return {
    type: RESET_PASSWORD_CONFIRM,
    payload: newRequest(requestObject)
  };
}

export function getCurrentUser () {
  const requestObject = {
    method: 'POST',
    url: urls.currentUser()
  };
  return {
    type: CURRENT_USER,
    payload: newRequest(requestObject)
  };
}

export function activateUser (search) {
  const requestObject = {
    method: 'GET',
    url: urls.activateUser(search)
  };
  return {
    type: ACTIVATE_USER,
    payload: newRequest(requestObject)
  };
}

export function logoutUser () {
  return {
    type: LOGOUT_USER
  };
}
