import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {publicKey} from 'helpers/private/PRIVATE_KEYS';
import { loadReCaptcha } from 'react-recaptcha-v3';
import LoginForm from '../components/Client/Authentication/LoginForm';
import AppNavBar from 'components/App/AppNavBar';

export function Login (props) {

  useEffect(() => {
    loadReCaptcha(publicKey);
  }, []);
  return (

    <> 
      <div className="App">
        <AppNavBar {...props}></AppNavBar>
        <div className="auth-wrapper">
          <div className="auth-inner">
            <LoginForm {...props}/>
          </div>
        </div>
      </div>
    </>
  );
}

Login.propTypes = {
  history: PropTypes.object
};

export default Login;
