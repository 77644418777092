import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

Instructions.propTypes = {

};

function Instructions (props) {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <h2>{t('instruction heading')}</h2>
      </div>
      <div className='step-progress'>
        <div className="auth-wrapper">
          <div className="steps-inner">
            <Trans>
            Welcome to horsePortal
            </Trans>
            <p>
              <Trans i18nKey="details">trans</Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instructions;
