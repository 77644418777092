import React from 'react';
import ProtectedRoute from './protected.route';
import UserRoute from './user.route';
import GuestRoute from './guest.route';
import MainPage from 'pages/MainPage';
import LoginPage from '../pages/Login';
import ResetPasswordPage from '../pages/ResetPassword';
import ActivationPage from '../pages/ActivationPage';
import RegisterPage from '../pages/Register';
import RequestsPage from 'pages/Requests';
import ImprintPage from 'pages/imprint';
import DataPolicyPage from 'pages/dataPolicy';

import { Switch, Route } from 'react-router-dom';

export const AppRoutes = (props) => {
  return (
    <Switch>
      <GuestRoute path="/home" component={MainPage} {...props}/>
      <GuestRoute path="/imprint" component={ImprintPage} />
      <Route path="/policy" component={DataPolicyPage} />
      <UserRoute path="/register" component={RegisterPage} {...props}/>
      <UserRoute path="/password-reset/:token" component={ResetPasswordPage} {...props}/>
      <UserRoute path="/password-reset" component={ResetPasswordPage} {...props}/>
      <UserRoute path="/activate" component={ActivationPage} {...props}/>
      <UserRoute path="/login" component={LoginPage} {...props}/>
      <ProtectedRoute path="/requests" component={RequestsPage} {...props}/>
      <ProtectedRoute path="/" component={MainPage} {...props}/>
    </Switch>
  );
};
export default AppRoutes;
