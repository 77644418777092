import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import StepZilla from 'react-stepzilla';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Formik, Form } from 'formik';
import { transportRequestSchema } from 'helpers/validation';
import { useTranslation } from 'react-i18next';

import Step3 from './ReviewConf';
import Step2 from './TransportGroup';
import Step1 from './InvoiceAddress';
import Step4 from './FinalStep';
import Instructions from './Instructions';
import { initialDate } from 'helpers/requestContent';

const steps = (values, props, setFieldValue, t, disableStepsNavigation) => {
  return [
    { name: `${t('Instructions')}`, component: <Instructions></Instructions> },
    { name: `${t('Invoice Address')}`, component: <Step1 values={values} t={t} setFieldValue={setFieldValue} {...props}/> },
    { name: `${t('Transport Group')}`, component: <Step2 values={values} t={t} setFieldValue={setFieldValue} {...props}/> },
    { name: `${t('Review')}`, component: <Step3 t={t} values={values} setFieldValue={setFieldValue} disableStepsNavigation={disableStepsNavigation} {...props}/> },
    { name: `${t('Submit')}`, component: <Step4 values={values} {...props}/> }
  ];
};

export const Index = (props) => {
  const { t } = useTranslation();
  const [navigation, disableNavigation] = React.useState(true);
  const [copiedData, setCopiedData] = React.useState(initialDate);

  React.useEffect(() => {
    const { requests } = props;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('id')) {
      for (var i = 0; i < requests.length; i++) {
        if (Number(urlParams.get('id')) === requests[i].id) {
          // check if request is return type then invert all pickup addresses with destination addresses
          let requestObj = requests[i]
          if (urlParams.get('type') === "returnRequest"){
            requestObj = invertAddresses(requests[i])
          }
          setCopiedData(requestObj);
          break;
        }
      }
    }
  }, [props]);

  const invertAddresses = (request) => {
    //swap picup address with destination address

    //create deep copy of json object
    let temp = JSON.parse(JSON.stringify(request))
    if (temp && temp.transportGroups){
      temp.transportGroups[0].pickup = request.transportGroups[0].destination
      temp.transportGroups[0].destination = request.transportGroups[0].pickup
    }
    console.log('request')
    console.log(request)
    console.log('temp')
    console.log(temp)
    return temp;
  };

  const disableStepsNavigation = () => {
    disableNavigation(false);
  };

  return (
    <div className='step-progress'>
      <Formik
        enableReinitialize={true}
        initialValues={copiedData}
        validationSchema={transportRequestSchema}
        onSubmit={(values, { setSubmitting }) => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Fragment>
            <Form className="m-t-20">
              <div className="m-t-60">
                <StepZilla
                  steps={steps(values, props, setFieldValue, t, disableStepsNavigation)}
                  prevBtnOnLastStep={false}
                  dontValidate= {false}
                  stepsNavigation={navigation}
                  preventEnterSubmission={true}
                  nextButtonText= {t('Next')}
                  backButtonText= {t('Back')}
                  nextButtonCls="btn btn-secondary steps-colors btn-lg float-right m-r-p-10 m-t-p-1 m-b-10 m-b-10"
                  backButtonCls="btn btn-secondary steps-colors btn-lg float-left m-l-p-10 m-t-p-1 m-b-10 m-b-10"
                  nextTextOnFinalActionStep= {t('Submit')}
                />
              </div>
            </Form>
          </Fragment>
        )}
      </Formik>
    </div>
  );
};

Index.propTypes = {
  requests: PropTypes.object
};

export default compose(
  reduxForm({
    form: 'transportRequestForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }),
  connect(state => ({
    user: state.user.user,
    requests: state.requests.requests
  }), {}))(Index);
