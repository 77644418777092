import axios from 'axios';
import { getToken, logout } from './auth';
import { _exists, msgAlert } from './common';
require('dotenv').config();

// const API_URL = process.env.REACT_APP_API_URL;

export async function newRequest ({ method, url, params, data, headers, hideError, auth , t }, server) {
  // url = API_URL + url;
  const token = getToken();
  if (token) {
    headers = { ...headers };
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }
  else {
    headers = null;
  }

  const response = await axios({ method, url, headers, data, auth  }).catch(({
    response
  }) => {
    if(!t) t = (m) => m;
    if (_exists(response, 'data.detail') && response.data.detail.includes('expired')) {
      logout();
      window.location.href = '/login';
      msgAlert(t('failure'), t('Token is Expired. Please log in'),t);
      return;
    }
    if (_exists(response, 'data.non_field_errors')) {
      msgAlert(t('failure'), t(response.data.non_field_errors[0]),t);
      // window.location.href = "/login";
      return;
    }

    throw response

    // return { data: [] };
  });
  return response;
}

export function pending (type) {
  return `${type}_PENDING`;
}

export function fulfilled (type) {
  return `${type}_FULFILLED`;
}
