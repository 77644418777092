import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {publicKey} from 'helpers/private/PRIVATE_KEYS';
import { loadReCaptcha } from 'react-recaptcha-v3';
import ResetPasswordForm from '../components/Client/Authentication/ResetPasswordForm';
import ResetPasswordConfirmForm from '../components/Client/Authentication/ResetPasswordConfirmForm';
import AppNavBar from 'components/App/AppNavBar';

export function ResetPassword (props) {
  const isToken = props.match.params.token ? true : false;
  useEffect(() => {
    // const { requests } = props;
    loadReCaptcha(publicKey);
  }, []);

  return (

    <>
      <div className="App">
      <AppNavBar {...props}></AppNavBar>
       <div className="auth-wrapper">
          <div className="auth-inner">
            {
              isToken  ? <ResetPasswordConfirmForm {...props}/> :
              <ResetPasswordForm {...props}/>
            }
          </div>
        </div>
      </div>
    </>
  );
}

ResetPassword.propTypes = {
  history: PropTypes.object
};

export default ResetPassword;
