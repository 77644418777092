import Swal from 'sweetalert2';
import lodash from 'lodash';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 8000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});
export function sweetToast (type, msg) {
  Toast.fire({
    icon: type,
    title: msg
  });
}

export function msgAlert (state, msg,t) {
  if(!t) t = (m) => m;
  if (state === 'success') {
    Swal.fire(t('Success!'), msg, 'success');
  } else if (state === 'failure') {
    Swal.fire(t('Failed!'), msg, 'error');
  }
}

// check if key exist in an object. keyPath is in string "object.keyname"
export function ifKeyExist (obj, keyPath) {
  return lodash.get(obj, keyPath);
}

export function deepCopy (data) {
  return JSON.parse(JSON.stringify(data));
}

export function _exists (obj, keyPath) {
  return lodash.get(obj, keyPath);
}

export function capitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function decamelize (str, separator) {
  separator = typeof separator === 'undefined' ? '_' : separator;

  return capitalize(str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2'));
}

export function changeUrlForServer (server, url) {
  if (server && server.req) { return `http://localhost:3000${url}`; } else { return url; }
}

export function getLanguage(){
  let lang = localStorage.getItem('i18nextLng');
  if(lang.includes('en')) lang = 'en';
  else if(lang.includes('de')) lang = 'de';

  return lang;
}
