import { LOGIN_USER, CURRENT_USER, LOGOUT_USER } from './user.types';
import { fulfilled } from 'helpers/api';
import humps from 'humps';
import { deformatUser } from 'helpers/dataDeformater';
const initial = {
  user: {}
};

export default function (state = initial, action) {
  switch (action.type) {
    case fulfilled(LOGIN_USER):
      return { ...state, user: action.payload ? deformatUser(humps.camelizeKeys(action.payload.data.user)) : {} };
    case fulfilled(CURRENT_USER):
      return { ...state, user: action.payload ? deformatUser(humps.camelizeKeys(action.payload.data.user)) : {} };
    case LOGOUT_USER:
      return { ...state, user: {} };
    default:
      return state;
  }
}
