import React, {useEffect , useState} from 'react';
import {useDispatch} from 'react-redux'
import PropTypes from 'prop-types';
import {publicKey} from 'helpers/private/PRIVATE_KEYS';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { activateUser } from 'store/modules/user/user.action';
import { msgAlert } from 'helpers/common';
import { useTranslation } from 'react-i18next';
import AppNavBar from 'components/App/AppNavBar';

export function ActivationPage (props) {
  const dispatch  = useDispatch();
  const [showMessage, setShowMessage] = useState('Please wait....');
  const {  t } = useTranslation();
  const search = props.location.search 
  useEffect(() => {
    // const { requests } = props;
    loadReCaptcha(publicKey);
    dispatch(activateUser(search)).then((res)=>{
      if (res.value && res.value.data.status) {
        msgAlert('success',t('Thanks for signing up! You can login now'),t)
        props.history.push('/login');
      }
    }).catch((err)=>{
      msgAlert('failure',t('Invalid Token'),t)
      setShowMessage(t('Invalid Token'),t)
    })
    // eslint-disable-next-line
  }, []);


  return (

    <>
      <div className="App">
      <AppNavBar {...props}></AppNavBar>
        <div className="auth-wrapper">
          <div className="auth-inner">
            {showMessage}
            {/* {
              isToken  ? <ResetPasswordConfirmForm {...props}/> :
              <ResetPasswordForm {...props}/>
            } */}
          </div>
        </div>
      </div>
    </>
  );
}

ActivationPage.propTypes = {
  history: PropTypes.object
};

export default ActivationPage;
