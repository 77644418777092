import React, {useState, Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Formik, Form } from 'formik';
import { loginSchema } from 'helpers/validation';
import { ifKeyExist } from 'helpers/common';

import { globalLoading } from 'store/modules/index';
import { login } from 'store/modules/user/user.action';
import { authlogin } from 'helpers/auth';
import { useTranslation, Trans } from 'react-i18next';
import {msgAlert} from 'helpers/common';

import { ReCaptcha } from 'react-recaptcha-v3';
import { verifyRecaptchaToken } from 'store/modules/raCaptcha/actions';
import {publicKey, privateKey} from 'helpers/private/PRIVATE_KEYS';
import InputField from 'components/UI/InputField';

export const LoginForm = (props) => {
  const [isValidate, setValidate]= useState(false);
  const [dataValues, setValues]= useState({});
  const { t } = useTranslation();

  const verifyCallback= async (recaptchaToken) => {
    const { verifyRecaptchaToken, globalLoading, login, history } = props;
    globalLoading(true);
    const res = await verifyRecaptchaToken(privateKey, recaptchaToken);
    if (ifKeyExist(res, 'value.data.data.success')) {
      if (res.value.data.data.success === true) {
        const loginRes = await login(dataValues,t);
        // setSubmitting(false);
        if (loginRes.value && loginRes.value.data) {
          authlogin(loginRes.value.data);
          history.push('/home');
        }
      } else {
        msgAlert({ state:'failure', msg:t('Malicious activity found') },t);
      }
    }
    globalLoading(false);
  }

  const createRequest = () => {
    props.history.push('/home');
  };

  return (
    <Fragment>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginSchema}
        onSubmit={ async (values, { setSubmitting }) => {
          setValues(values);
          setValidate(true);
          setValidate(false);
          props.globalLoading(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <h3><Trans>Sign In</Trans></h3>
            <div className="form-group">
              <InputField label='Email Address' type='email' name="email" placeholder={'Enter Email'}/>
            </div>
            <div className="form-group">
              <InputField label='Password' type='password' name="password" placeholder={'Enter password'}/>
            </div>
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                <label className="custom-control-label" htmlFor="customCheck1"><Trans>Remember me</Trans></label>
              </div>
            </div>

            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
              <Trans>Submit</Trans>
            </button>
            <button type="button" className="btn btn-secondary btn-block" disabled={isSubmitting} onClick={createRequest}>
              <Trans>Login As Guest</Trans>
            </button>
            <p className="forgot-password text-left">
              <Trans>Dont have any account</Trans>? <Link to='/register'><Trans>Register here</Trans></Link>
            </p>
            <p className="forgot-password text-left">
              <Trans>Forgot Password</Trans>? <Link to={'/password-reset'}><Trans>Reset Password</Trans></Link>
            </p>

            {isValidate && (
              <ReCaptcha
                sitekey={publicKey}
                action="RequestPage"
                verifyCallback={ verifyCallback }
              />
            )}
          </Form>
        )}
      </Formik>
      
  </Fragment>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func,
  history: PropTypes.object,
  globalLoading: PropTypes.func
};

export default connect(null, { login, globalLoading, verifyRecaptchaToken })(LoginForm);
