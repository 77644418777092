import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Redux imports
import { Provider } from 'react-redux';
import configureStore from './store/store';
import { BrowserRouter } from 'react-router-dom';

import './i18n';
// import { I18nextProvider } from 'react-i18next';
// import i18next from 'i18next';
// import common_en from './translations/en/common.json';
// import common_de from './translations/de/common.json';

const store = configureStore();
// i18next.init({
//   interpolation: { escapeValue: false }, // React already does escaping
//   lng: 'de', // language to use
//   resources: {
//     en: {
//       common: common_en // 'common' is our custom namespace
//     },
//     de: {
//       common: common_de
//     }
//   }
// });

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <I18nextProvider i18n={i18next}> */}
      <App/>
      {/* </I18nextProvider> */}
    </BrowserRouter>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
