import React from 'react';
import PropTypes from 'prop-types';

import { CountriesPicker } from 'components/Forms/Fields/CountriesPicker';
import { SearchField } from 'components/Forms/Fields/SearchLocationField';
import { Field, ErrorMessage } from 'formik';
import { useTranslation, Trans } from 'react-i18next';

function DestAddress (props) {
  const { groupName, index, location } = props;
  const { t } = useTranslation();

  function validateField (value) {
    let error;
    if (value === '') {
      error = 'Required';
    }
    return error;
  }

  return (
    <div className='row text-left'>
      <div className='col-md-4 m-l-3 m-b-40'>
        <SearchField id={`autocomplete-d-${index}`} onLocationSelect={props.onLocationSelect} t={t}/>
      </div><div className='col-md-3'></div><div className='col-md-4'></div>
      <div className="col-md-4 form-group">
        <label><Trans>Farm name</Trans></label>
        <Field type="text" className="form-control" name={`${groupName}.${index}.destination.farmName`} placeholder={t('Farm Name')} />
        <ErrorMessage  name={`${groupName}.${index}.destination.farmName`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label><Trans>Name</Trans><span className='required-field'>*</span></label>
        <Field type="text" className="form-control" name={`${groupName}.${index}.destination.name`} validate={validateField} placeholder={t('Name')} />
        <ErrorMessage  name={`${groupName}.${index}.destination.name`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label><Trans>Street</Trans><span className='required-field'>*</span></label>
        <Field type="text" className={`form-control ${location.street ? 'fields-shadow' : ''}`} name={`${groupName}.${index}.destination.street`} validate={validateField} placeholder={t('Street')} />
        <ErrorMessage  name={`${groupName}.${index}.destination.street`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label><Trans>Zip-Code</Trans><span className='required-field'>*</span></label>
        <Field type="text" className={`form-control ${location.zipCode ? 'fields-shadow' : ''}`} name={`${groupName}.${index}.destination.zipCode`} validate={validateField} placeholder={t('Zip-Code')} />
        <ErrorMessage  name={`${groupName}.${index}.destination.zipCode`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label><Trans>City</Trans><span className='required-field'>*</span></label>
        <Field type="text" className={`form-control ${location.city ? 'fields-shadow' : ''}`} name={`${groupName}.${index}.destination.city`} validate={validateField} placeholder={t('City')} />
        <ErrorMessage  name={`${groupName}.${index}.destination.city`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label><Trans>Country</Trans><span className='required-field'>*</span></label>
        <CountriesPicker location={location} name={`${groupName}.${index}.destination.country`} validate={validateField}/>
        <ErrorMessage  name={`${groupName}.${index}.destination.country`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label>{t('Email')}</label>
        <Field type="email" className='form-control' name={`${groupName}.${index}.destination.email`} placeholder={t('Email')} />
        <ErrorMessage  name={`${groupName}.${index}.destination.email`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label>{t('Telephone')}</label>
        <Field type="text" className='form-control' name={`${groupName}.${index}.destination.phoneNumber1`} placeholder={t('Telephone')} />
        <ErrorMessage  name={`${groupName}.${index}.destination.phoneNumber1`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>

      <div className="col-md-4 form-group">
        <label>{t('Alternative Telephone')}</label>
        <Field type="text" className='form-control' name={`${groupName}.${index}.destination.phoneNumber2`} placeholder={t('Alternative Telephone')} />
        <ErrorMessage  name={`${groupName}.${index}.destination.phoneNumber2`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
      </div>
    </div>
  );
}

DestAddress.propTypes = {
  groupName: PropTypes.string,
  index: PropTypes.number,
  values: PropTypes.object,
  onLocationSelect: PropTypes.func,
  location: PropTypes.object
};

export default DestAddress;
