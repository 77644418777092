import React, {useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Formik, Form} from 'formik';
import { resetPasswordConfirmSchema } from 'helpers/validation';
import { ifKeyExist } from 'helpers/common';

import { globalLoading } from 'store/modules/index';
import { resetPasswordConfirm } from 'store/modules/user/user.action';
import { useTranslation, Trans } from 'react-i18next';
import {msgAlert} from 'helpers/common';

import { ReCaptcha } from 'react-recaptcha-v3';
import { verifyRecaptchaToken } from 'store/modules/raCaptcha/actions';
import {publicKey, privateKey} from 'helpers/private/PRIVATE_KEYS';
import InputField from 'components/UI/InputField';

let formikErrors = {};
export const ResetPasswordConfirmForm = (props) => {
  const [isValidate, setValidate]= useState(false);
  const [dataValues, setValues]= useState({});
  const { t } = useTranslation();

  const verifyCallback= async (recaptchaToken) => {
    const { verifyRecaptchaToken, globalLoading, resetPasswordConfirm, match } = props;
    globalLoading(true);
    const res = await verifyRecaptchaToken(privateKey, recaptchaToken);
    if (ifKeyExist(res, 'value.data.data.success')) {
      if (res.value.data.data.success === true) {
        try{
          dataValues.token = match.params.token;
          const resetRes = await resetPasswordConfirm(dataValues);
          // setSubmitting(false);
          if (resetRes.value && resetRes.value.data.status) {
            msgAlert('success', t('Password Changed Successfully'),t);
            props.history.push('/login');
          }
        }catch(err){
          if(err.status === 404){
            msgAlert( 'failure', t('The password reset link was invalid, possibly because it has already been used.Please request a new password reset.'),t );
            props.history.push('/password-reset');
          } else
            formikErrors(err.data);
        } 
        
      } else {
        msgAlert( 'failure', t('Malicious activity found'),t );
      }
    }
    globalLoading(false);
  }


  return (
    <>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={resetPasswordConfirmSchema}
        onSubmit={ async (values, { setErrors }) => {
          formikErrors =  setErrors;
          setValues(values);
          setValidate(true);
          setValidate(false);
          props.globalLoading(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <h3><Trans>Reset Password</Trans></h3>
            <div className="form-group">
              <InputField type="email" name="email" placeholder={t('Email Address')} label="Email Address"  required/>
            </div>

            <div className="form-group">
              <InputField type="password" name="password" placeholder={t('Password')} label="Password"  required/>
            </div>

            <div className="form-group">
              <InputField type="password" name="confirmPassword" placeholder={t('Confirm Password')} label="Confirm Password"  required/>
            </div>

            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
              <Trans>Submit</Trans>
            </button>

            {isValidate && (
              <ReCaptcha
                sitekey={publicKey}
                action="RequestPage"
                verifyCallback={ verifyCallback }
              />
            )}
          </Form>
        )}
      </Formik>
      
  </>
  );
};

ResetPasswordConfirmForm.propTypes = {
  login: PropTypes.func,
  history: PropTypes.object,
  globalLoading: PropTypes.func
};

export default connect(null, { resetPasswordConfirm, globalLoading, verifyRecaptchaToken })(ResetPasswordConfirmForm);
