const GLOBAL_LOADING = 'GLOBAL_LOADING';

const initialState = {
  activeModule: '',
  globalLoading: false
};

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case GLOBAL_LOADING:
      return { ...state, globalLoading: action.state };
    default:
      return state;
  }
}

export function globalLoading (state) {
  return (dispatch) => dispatch({
    type: GLOBAL_LOADING,
    state
  });
}
