import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, BrowserRouter as Router } from 'react-router-dom';
import { logout, isLogin } from 'helpers/auth';
import RequestList from 'components/Client/Requests/RequestList';
import { getRequestList, clearRequests } from 'store/modules/request/request.action';
import { deformatRequests } from 'helpers/dataDeformater';
import { globalLoading } from 'store/modules/index';
import { msgAlert } from 'helpers/common';
import { logoutUser } from 'store/modules/user/user.action';

import humps from 'humps';

export function RequestsPage (props) {
  const [currentLanguage, setLanguage] = React.useState({ key: localStorage.getItem('i18nextLng').includes('en') ? 'English' : 'German', value: localStorage.getItem('i18nextLng').includes('en') ? 'en' : 'de' });
  const [previousRequests, setRequests] = useState([]);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (isLogin()) {
      const fetchRequests = async () => {
        const { globalLoading } = props;
        globalLoading(true);
        const response = await props.getRequestList();
        if (response && response.value && response.value.data.status === true) {
          setRequests(deformatRequests(humps.camelizeKeys(response.value.data.response.data)));
        } else {
          msgAlert('failure', t('Data fetching failure'),t);
        }
        globalLoading(false);
      };
      fetchRequests();
    }
  }, [props,t]);

  const onLogout = () => {
    const { history, logoutUser, clearRequests } = props;
    history.push('/login');
    clearRequests();
    logoutUser();
    logout();
  };

  const onHome = () => {
    props.history.push('/home');
  };

  const changeLanguage = lng => {
    setLanguage({ key: lng === 'en' ? 'English' : 'German', value: lng });
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Router>
        <div className="App">

        <nav className="navbar navbar-expand-lg navbar-light navbar-fixed-top">
            <div className="container">
              <Link onClick={()=>{ window.location.reload()}} className="navbar-brand" to={'/home'}>{t('Horse Portal')}</Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link className="nav-link c-pointer" onClick={onHome}>{t('Home')}</Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle"  id="dropdown09" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="flag-icon flag-icon-us"> </span> {currentLanguage.key}</Link>
                    <div className="dropdown-menu" aria-labelledby="dropdown09">
                      <Link className="dropdown-item"  onClick={() => changeLanguage('en')}><span className="flag-icon flag-icon-fr"> </span>  English</Link>
                      <Link className="dropdown-item"  onClick={() => changeLanguage('de')}><span className="flag-icon flag-icon-it"> </span>  German</Link>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link c-pointer" onClick={onLogout}>{t('Logout')}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <RequestList {...props} requests={previousRequests} />
        </div>
      </Router>
    </>
  );
}

RequestsPage.propTypes = {
  history: PropTypes.object,
  getRequestList: PropTypes.func,
  globalLoading: PropTypes.func,
  clearRequests: PropTypes.func,
  logoutUser: PropTypes.func
};

export default connect(null, { getRequestList, globalLoading, clearRequests, logoutUser })(RequestsPage);
