import React from 'react';

function dataPolicy(props) {
    return (
        <div className='text-left padding-all-2'>
            <h1>Datenschutzerkl&auml;rung</h1>
<p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
<p>Ingo M&uuml;ller<br />Dedendorf 14<br />27333 B&uuml;cken</p>
<h2>Ihre Betroffenenrechte</h2>
<p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten k&ouml;nnen Sie jederzeit folgende Rechte aus&uuml;ben:</p>
<ul>
<li>Auskunft &uuml;ber Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
<li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
<li>L&ouml;schung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
<li>Einschr&auml;nkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht l&ouml;schen d&uuml;rfen (Art. 18 DSGVO),</li>
<li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
<li>Daten&uuml;bertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
</ul>
<p>Sofern Sie uns eine Einwilligung erteilt haben, k&ouml;nnen Sie diese jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen.</p>
<p>Sie k&ouml;nnen sich jederzeit mit einer Beschwerde an die f&uuml;r Sie zust&auml;ndige Aufsichtsbeh&ouml;rde wenden (Art. 77 DSGVO i.V.m. &sect; 19 BDSG). Ihre zust&auml;ndige Aufsichtsbeh&ouml;rde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutma&szlig;lichen Verletzung. Eine Liste der Aufsichtsbeh&ouml;rden (f&uuml;r den nicht&ouml;ffentlichen Bereich) mit Anschrift finden Sie unter:&nbsp;<a target="_blank" rel="noopener noreferrer" href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" >https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
<h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen &uuml;bermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und &auml;hnliches. Hierbei handelt es sich ausschlie&szlig;lich um Informationen, welche keine R&uuml;ckschl&uuml;sse auf Ihre Person zulassen.</p>
<p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
<ul>
<li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
<li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
<li>Auswertung der Systemsicherheit und -stabilit&auml;t sowie</li>
<li>zu weiteren administrativen Zwecken.</li>
</ul>
<p>Wir verwenden Ihre Daten nicht, um R&uuml;ckschl&uuml;sse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.</p>
<h3>Rechtsgrundlage:</h3>
<p>Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website.</p>
<h3>Empf&auml;nger:</h3>
<p>Empf&auml;nger der Daten sind nur die verantwortliche Stelle und ggf. technische Dienstleister, die f&uuml;r den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter t&auml;tig werden.</p>
<h3>Speicherdauer:</h3>
<p>Die Daten werden gel&ouml;scht, sobald diese f&uuml;r den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist f&uuml;r die Daten, die der Bereitstellung der Webseite dienen, grunds&auml;tzlich der Fall, wenn die jeweilige Sitzung beendet ist.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsf&auml;higkeit unserer Website nicht gew&auml;hrleistet. Zudem k&ouml;nnen einzelne Dienste und Services nicht verf&uuml;gbar oder eingeschr&auml;nkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen.</p>
<h2>Erbringung kostenpflichtiger Leistungen</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Zur Erbringung kostenpflichtiger Leistungen werden von uns zus&auml;tzliche Daten erfragt, wie z.B. Zahlungsangaben, um Ihre Bestellung ausf&uuml;hren zu k&ouml;nnen.</p>
<h3>Rechtsgrundlage:</h3>
<p>Die Verarbeitung der Daten, die f&uuml;r den Abschluss des Vertrages erforderlich ist, basiert auf Art. 6 Abs. 1 lit. b DSGVO.</p>
<h3>Empf&auml;nger:</h3>
<p>Empf&auml;nger der Daten sind nur die verantwortliche Stelle und ggf. Auftragsverarbeiter.</p>
<h3>Speicherdauer:</h3>
<p>Wir speichern diese Daten in unseren Systemen bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind. Diese betragen grunds&auml;tzlich 6 oder 10 Jahre aus Gr&uuml;nden der ordnungsm&auml;&szlig;iger Buchf&uuml;hrung und steuerrechtlichen Anforderungen.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die Bereitstellung Ihrer personenbezogenen Daten k&ouml;nnen wir Ihnen keinen Zugang auf unsere angebotenen Inhalte und Leistungen gew&auml;hren.</p>
<h2>Verwendung von Google Analytics</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend: &bdquo;Google&ldquo;). Google Analytics verwendet sog. &bdquo;Cookies&ldquo;, also Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie erm&ouml;glichen. Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports &uuml;ber die Webseitenaktivit&auml;ten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegen&uuml;ber dem Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengef&uuml;hrt.</p>
<p>Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung der Website und in der Zusammenstellung von Reports &uuml;ber Aktivit&auml;ten auf der Website. Auf Grundlage der Nutzung der Website und des Internets sollen dann weitere verbundene Dienstleistungen erbracht werden.</p>
<h3>Rechtsgrundlage:</h3>
<p>Die Verarbeitung der Daten erfolgt auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</p>
<h3>Empf&auml;nger:</h3>
<p>Empf&auml;nger der Daten sind die verantwortliche Stelle und Google als Auftragsverarbeiter. Hierf&uuml;r haben wir mit Google den entsprechenden Auftragsverarbeitungsvertrag abgeschlossen.</p>
<h3>Speicherdauer:</h3>
<p>Die L&ouml;schung der Daten erfolgt, sobald diese f&uuml;r unsere Aufzeichnungszwecke nicht mehr erforderlich sind.</p>
<h3>Drittlandtransfer:</h3>
<p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen&nbsp;<a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>. Au&szlig;erdem haben wir Standarddatenschutzklauseln mit Google f&uuml;r den Einsatz von Google Analytics abgeschlossen.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschr&auml;nkungen auf der Website kommen.</p>
<h3>Widerruf der Einwilligung:</h3>
<p>Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verf&uuml;gbare Browser-Plugin herunterladen und installieren:&nbsp;<a href="http://tools.google.com/dlpage/gaoptout?hl=de">Browser Add On zur Deaktivierung von Google Analytics</a>.</p>
<p>Zus&auml;tzlich oder als Alternative zum Browser-Add-On k&ouml;nnen Sie das Tracking durch Google Analytics auf unseren Seiten unterbinden, indem Sie&nbsp;<a title="Google Analytics Opt-Out-Cookie setzen" href="https://mueller-pferdetransporte.de/anfrage/datenschutz.html">diesen Link anklicken</a>. Dabei wird ein Opt-out-Cookie auf Ihrem Ger&auml;t installiert. Damit wird die Erfassung durch Google Analytics f&uuml;r diese Website und f&uuml;r diesen Browser zuk&uuml;nftig verhindert, so lange das Cookie in Ihrem Browser installiert bleibt.</p>
<h3>Profiling:</h3>
<p>Mit Hilfe des Tracking-Tools Google Analytics kann das Verhalten der Besucher der Webseite bewertet und die Interessen analysiert werden. Hierzu erstellen wir ein pseudonymes Nutzerprofil.</p>
<h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Um unsere Inhalte browser&uuml;bergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website &bdquo;Google Web Fonts&ldquo; der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend &bdquo;Google&ldquo;) zur Darstellung von Schriften.</p>
<p>Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier:&nbsp;<a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></p>
<h3>Rechtsgrundlage:</h3>
<p>Rechtsgrundlage f&uuml;r die Einbindung von Google Webfonts und dem damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p>
<h3>Empf&auml;nger:</h3>
<p>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken l&ouml;st automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch m&ouml;glich &ndash; aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken &ndash; dass der Betreiber in diesem Fall Google Daten erhebt.</p>
<h3>Speicherdauer:</h3>
<p>Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google Webfonts.</p>
<p>Weitere Informationen zu Google Web Fonts finden Sie unter&nbsp;<a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a>&nbsp;und in der Datenschutzerkl&auml;rung von Google:&nbsp;<a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</p>
<h3>Drittlandtransfer:</h3>
<p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen&nbsp;<a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich, noch vertraglich vorgeschrieben. Allerdings kann ohne die korrekte Darstellung der Inhalte von Standardschriften nicht erm&ouml;glicht werden.</p>
<h3>Widerruf der Einwilligung:</h3>
<p>Zur Darstellung der Inhalte wird regelm&auml;&szlig;ig die Programmiersprache JavaScript verwendet. Sie k&ouml;nnen der Datenverarbeitung daher widersprechen, indem Sie die Ausf&uuml;hrung von JavaScript in Ihrem Browser deaktivieren oder einen Einbindung JavaScript-Blocker installieren. Bitte beachten Sie, dass es hierdurch zu Funktionseinschr&auml;nkungen auf der Website kommen kann.</p>
<h2>Verwendung von Google Maps</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Auf dieser Webseite nutzen wir das Angebot von Google Maps. Google Maps wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend &bdquo;Google&ldquo;) betrieben. Dadurch k&ouml;nnen wir Ihnen interaktive Karten direkt in der Webseite anzeigen und erm&ouml;glichen Ihnen die komfortable Nutzung der Karten-Funktion.</p>
<p>N&auml;here Informationen &uuml;ber die Datenverarbeitung durch Google k&ouml;nnen Sie&nbsp;<a href="http://www.google.com/privacypolicy.html">den Google-Datenschutzhinweisen</a>&nbsp;entnehmen. Dort k&ouml;nnen Sie im Datenschutzcenter auch Ihre pers&ouml;nlichen Datenschutz-Einstellungen ver&auml;ndern.</p>
<p>Ausf&uuml;hrliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten<a href="http://www.dataliberation.org/">&nbsp;finden Sie hier</a>.</p>
<h3>Rechtsgrundlage:</h3>
<p>Rechtsgrundlage f&uuml;r die Einbindung von Google Maps und dem damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p>
<h3>Empf&auml;nger:</h3>
<p>Durch den Besuch der Webseite erh&auml;lt Google Informationen, dass Sie die entsprechende Unterseite unserer Webseite aufgerufen haben. Dies erfolgt unabh&auml;ngig davon, ob Google ein Nutzerkonto bereitstellt, &uuml;ber das Sie eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet.</p>
<p>Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht w&uuml;nschen, m&uuml;ssen Sie sich vor Aktivierung des Buttons bei Google ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie f&uuml;r Zwecke der Werbung, Marktforschung und/oder bedarfsgerechter Gestaltung seiner Webseite. Eine solche Auswertung erfolgt insbesondere (selbst f&uuml;r nicht eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks &uuml;ber Ihre Aktivit&auml;ten auf unserer Webseite zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Aus&uuml;bung dessen an Google richten m&uuml;ssen.</p>
<h3>Speicherdauer:</h3>
<p>Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google Maps.</p>
<h3>Drittlandtransfer:</h3>
<p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen&nbsp;<a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
<h3>Widerruf der Einwilligung:</h3>
<p>Wenn Sie nicht m&ouml;chten, dass Google &uuml;ber unseren Internetauftritt Daten &uuml;ber Sie erhebt, verarbeitet oder nutzt, k&ouml;nnen Sie in Ihrem Browsereinstellungen JavaScript deaktivieren. In diesem Fall k&ouml;nnen Sie unsere Webseite jedoch nicht oder nur eingeschr&auml;nkt nutzen.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschr&auml;nkungen auf der Website kommen.</p>
<h2>SSL-Verschl&uuml;sselung</h2>
<p>Um die Sicherheit Ihrer Daten bei der &Uuml;bertragung zu sch&uuml;tzen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschl&uuml;sselungsverfahren (z. B. SSL) &uuml;ber HTTPS.</p>
<h2>&Auml;nderung unserer Datenschutzbestimmungen</h2>
<p>Wir behalten uns vor, diese Datenschutzerkl&auml;rung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um &Auml;nderungen unserer Leistungen in der Datenschutzerkl&auml;rung umzusetzen, z.B. bei der Einf&uuml;hrung neuer Services. F&uuml;r Ihren erneuten Besuch gilt dann die neue Datenschutzerkl&auml;rung.</p>
<h2>Fragen an den Datenschutzbeauftragten</h2>
<p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die f&uuml;r den Datenschutz verantwortliche Person in unserer Organisation:</p>
<p>Ingo M&uuml;ller<br />Dedendorf 14<br />27333 B&uuml;cken</p>
<p><em>Die Datenschutzerkl&auml;rung wurde mit dem&nbsp;</em><em><a target="_blank" rel="noopener noreferrer" href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/" >Datenschutzerkl&auml;rungs-Generator der activeMind AG erstellt</a>&nbsp;(Version 2018-06-15).</em></p>
        </div>
    );
}

export default dataPolicy;