
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import LoaderSpinner from 'react-loader-spinner';
import BlockUi from 'react-block-ui';

import 'react-block-ui/style.css';

const ListLoader = props => (
  <div {...props}>
    {[...Array(props.duplicate)].map((x, i) => (
      <ContentLoader
        rtl
        key={i}
        height={70}
        width={300}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#c0c0c0"
      >
        <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
        <rect x="24" y="41.26" rx="5" ry="5" width="154" height="10" />
      </ContentLoader>
    )
    )}
  </div>
);

const ContentLoaderComponent = props => (
  <div {...props}>
    {[...Array(props.duplicate)].map((x, i) => (
      <ContentLoader
        rtl
        key={i}
        // height={475}
        width={300}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#c0c0c0"
        {...props}
      >
        <rect x="-77.86" y="-6" rx="5" ry="5" width="404" height="284" />
      </ContentLoader>
    )
    )}
  </div>
);
const ContentLoaderSpinner = props => (
  <div {...props}>
    <LoaderSpinner type="ThreeDots" color="#0C5BBA" height="100" width="100" />
  </div>
);

const BlockUiComponent = (props) => {
  const { children, loadingRef, loaderType, blocking, globalLoading } = props;

  let bl = true;
  if (loadingRef) {
    bl = globalLoading;
  } else {
    bl = blocking;
  }
  console.log(window.location.pathname);
  const lt = loaderType || 'ThreeDots';
  return (
    <BlockUi className={window.location.pathname === '/home' ? 'loaderblock auto-height' : 'loaderblock'} blocking={bl} keepInView loader={<LoaderSpinner type={lt} color="#0C5BBA" />}>
      {children}
    </BlockUi>
  );
};

const BlockUiFieldComponent = (props) => {
  const { children, loadingRef, loaderType, blocking, globalLoading } = props;

  let bl = true;
  if (loadingRef) {
    bl = globalLoading;
  } else {
    bl = blocking;
  }

  const lt = loaderType || 'ThreeDots';
  return (
    <BlockUi className="fieldloaderblock" blocking={bl} keepInView loader={<LoaderSpinner type={lt} color="#0C5BBA" width={30} height={30} />}>
      {children}
    </BlockUi>
  );
};

export const Loader = (props) => {
  Loader.propTypes = {
    type: PropTypes.string,
    duplicate: PropTypes.number
  };
  const { type } = props;



  return (
    <Fragment>
      {
        type === 'List' && <ListLoader></ListLoader>
      }
      {
        type === 'Content' && <ContentLoaderComponent></ContentLoaderComponent>
      }
      {
        type === 'ContentSpinner' && <ContentLoaderSpinner></ContentLoaderSpinner>
      }
      {
        type === 'BlockUi' && <BlockUiComponent {...props}></BlockUiComponent>
      }
      {
        type === 'BlockUiField' && <BlockUiFieldComponent {...props}></BlockUiFieldComponent>
      }
    </Fragment>
  );
};

export default connect(
  state => ({
    globalLoading: state.modules.globalLoading
  }), {}
)(Loader);
