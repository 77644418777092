import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import modules from './modules/index';
import user from './modules/user/user.reducer';
import requests from './modules/request/request.reducer';

const appReducer = combineReducers({
  modules,
  user,
  requests,
  form: reduxFormReducer
});
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER' || action.type === '@@redux-form/DESTROY') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
