import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser } from 'store/modules/user/user.action';

import { isLogin } from '../helpers/auth';

const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  const dispatch = useDispatch();
  if (isLogin()) {
    try {
      dispatch(getCurrentUser());
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isLogin()) {
          if(window.location.pathname === '/'){
            return (
              <Redirect
              to={{
                pathname: '/home',
                state: {
                  from: props.location
                }
              }}
            />
            )
          }
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any
};

export default ProtectedRoute;
