import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { SearchField } from 'components/Forms/Fields/SearchLocationField';
import { CountriesPicker } from 'components/Forms/Fields/CountriesPicker';
import { useTranslation } from 'react-i18next';

function validateField (value) {
  let error;
  if (value === '') {
    error = 'Required';
  }
  return error;
}

export function PickupAddress (props) {
  const { groupName, index, location } = props;
  const { t } = useTranslation();

  const setLocation = (value) => {
    const { setFieldValue, groupName, index } = props;
    if (value.target.value === 'LGG') {
      setFieldValue(`${groupName}.${index}.pickup.name`, 'Flughafen');
      setFieldValue(`${groupName}.${index}.pickup.street`, 'Rue de l’Aéroport');
      setFieldValue(`${groupName}.${index}.pickup.zipCode`, '4460');
      setFieldValue(`${groupName}.${index}.pickup.city`, 'Grâce-Hollogne');
      setFieldValue(`${groupName}.${index}.pickup.state`, 'Belgium');
      setFieldValue(`${groupName}.${index}.pickup.country`, 'BE');
    }
  };

  return (
    <Fragment>
      <div className="row text-left">
        <div className='col-md-4 m-b-40'>
          <SearchField id={`autocomplete-p-${index}`} onLocationSelect={props.onLocationSelect} t={t} />
        </div>
        <div className="col-md-4 m-b-40 form-group">
          <Field className="form-control" as="select" onChange={(value) => setLocation(value)} name='templateName' validate={validateField}>
            <option value="">--- {t('Select Template')} ---</option>
            <option value="LGG">LGG</option>
          </Field>
          <ErrorMessage className='error-text' name={`${groupName}.${index}.gender`} component="div" />
        </div>
        <div className='col-md-4'></div>
        <div className="col-md-4 form-group">
          <label>{t('Farm name')}</label>
          <Field type="text" className="form-control" name={`${groupName}.${index}.pickup.farmName`} placeholder={t('Farm name')} />
          <ErrorMessage  name={`${groupName}.${index}.pickup.farmName`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
        </div>

        <div className="col-md-4 form-group">
          <label>{t('Name')}<span className='required-field'>*</span></label>
          <Field type="text" className="form-control" name={`${groupName}.${index}.pickup.name`} validate={validateField} placeholder={t('Name')} />
          <ErrorMessage  name={`${groupName}.${index}.pickup.name`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
        </div>

        <div className="col-md-4 form-group">
          <label>{t('Street')}<span className='required-field'>*</span></label>
          <Field type="text" className={`form-control ${location.street ? 'fields-shadow' : ''}`} name={`${groupName}.${index}.pickup.street`} validate={validateField} placeholder={t('Street')} />
          <ErrorMessage  name={`${groupName}.${index}.pickup.street`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
        </div>

        <div className="col-md-4 form-group">
          <label>{t('Zip-Code')}<span className='required-field'>*</span></label>
          <Field type="text" className={`form-control ${location.zipCode ? 'fields-shadow' : ''}`} name={`${groupName}.${index}.pickup.zipCode`} validate={validateField} placeholder={t('Zip-Code')} />
          <ErrorMessage  name={`${groupName}.${index}.pickup.zipCode`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
        </div>

        <div className="col-md-4 form-group">
          <label>{t('City')}<span className='required-field'>*</span></label>
          <Field type="text" className={`form-control ${location.city ? 'fields-shadow' : ''}`} name={`${groupName}.${index}.pickup.city`} validate={validateField} placeholder={t('City')} />
          <ErrorMessage  name={`${groupName}.${index}.pickup.city`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
        </div>

        <div className="col-md-4 form-group">
          <label>{t('Country')}<span className='required-field'>*</span></label>
          <CountriesPicker location={location} name={`${groupName}.${index}.pickup.country`} validate={validateField}/>
          <ErrorMessage  name={`${groupName}.${index}.pickup.country`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
        </div>

        <div className="col-md-4 form-group">
          <label>{t('Email')}</label>
          <Field type="email" className='form-control' name={`${groupName}.${index}.pickup.email`} placeholder={t('Email')} />
          <ErrorMessage  name={`${groupName}.${index}.pickup.email`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
        </div>

        <div className="col-md-4 form-group">
          <label>{t('Telephone')}</label>
          <Field type="text" className='form-control' name={`${groupName}.${index}.pickup.phoneNumber1`} placeholder={t('Telephone')} />
          <ErrorMessage  name={`${groupName}.${index}.pickup.phoneNumber1`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
        </div>

        <div className="col-md-4 form-group">
          <label>{t('Alternative Telephone')}</label>
          <Field type="text" className='form-control' name={`${groupName}.${index}.pickup.phoneNumber2`} placeholder={t('Alternative Telephone')} />
          <ErrorMessage  name={`${groupName}.${index}.pickup.phoneNumber2`} >{msg => <div className='error-text'>{t(msg)}</div>}</ErrorMessage>
        </div>
      </div>
    </Fragment>
  );
}

PickupAddress.propTypes = {
  groupName: PropTypes.string,
  index: PropTypes.number,
  values: PropTypes.object,
  onLocationSelect: PropTypes.func,
  location: PropTypes.object,
  setFieldValue: PropTypes.func
};

export default PickupAddress;
