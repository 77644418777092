const AUTH_URL = process.env.REACT_APP_AUTH_URL;

export const urls = {
  login: () => `${AUTH_URL}/login`,
  register: (lang) => `${AUTH_URL}/register?lang=${lang}`,
  currentUser: () => `${AUTH_URL}/current`,
  activateUser: (search) => `${AUTH_URL}/activate${search}`,
  resetPassword: () => `${AUTH_URL}/reset-password/`,
  resetPasswordConfirm: () => `${AUTH_URL}/reset-password/confirm`
};
