// import { getCountry } from 'helpers/countryHelper';
import {msgAlert} from 'helpers/common';

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export function formatRequest (request) {
  if(!request.data.invoiceAddress.street && !request.data.invoiceAddress.addressLine1){
      msgAlert('failure', 'Street is missing.');
      return false;
    }
  delete request.data.id;
  delete request.data.createdAt;
  delete request.data.updatedAt;
  request.data.invoiceAddress.addressLine1 = request.data.invoiceAddress.street;
  request.data.invoiceAddress.addressLine2 = request.data.invoiceAddress.secondaryCompanyName;
  delete request.data.invoiceAddress.street;
  delete request.data.invoiceAddress.secondaryCompanyName;
  // request.data.invoiceAddress.state = getCountry(request.data.invoiceAddress.country);
  // if (!request.data.invoiceAddress.state) request.data.invoiceAddress.state = null;
  // if (!request.data.invoiceAddress.phoneNumber) request.data.invoiceAddress.phoneNumber = null;
  request.data.invoiceAddress.state = null;
  request.data.transportGroups.forEach(group => {
    delete group.id;
    delete group.createdAt;
    delete group.updatedAt;
    // group.pickup.state = getCountry(group.pickup.country);
    // group.destination.state = getCountry(group.destination.country);
    // if (!group.pickup.state) group.pickup.state = null;
    // if (!group.destination.state) group.destination.state = null;
    group.pickup.state = null;
    group.destination.state = null;
    group.periodStart = formatDate(group.periodStart);
    // let date = (group.periodStart.toLocaleString()).split('/');
    // if (date[0] && date[1] && date[2]) {
    //   group.periodStart = `${date[2].split(',')[0]}-${date[0]}-${date[1]}`;
    // }
    group.periodEnd = formatDate(group.periodEnd);
    // date = (group.periodEnd.toLocaleString()).split('/');
    // if (date[0] && date[1] && date[2]) {
    //   group.periodEnd = `${date[2].split(',')[0]}-${date[0]}-${date[1]}`;
    // }
    group.horses.forEach(horse => {
      delete horse.id;
      if (horse.age) horse.age = horse.age.toString(10);
      if (horse.height) horse.height = horse.height.toString(10);
    });
  });
  return true;
}
