import React from 'react';
import { Trans } from 'react-i18next';

function FInalStep (props) {
  return (
    <div className="auth-wrapper">
      <div className="steps-inner">
        <div className='margin'>
          <Trans>
              Your request has been submitted.
          </Trans>
        </div>
      </div>
    </div>
  );
}

export default FInalStep;
